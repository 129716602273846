import React, { useState, useEffect } from "react";
import Gender from "../../utils/gender";
import { ButtonGroup, Button } from "shards-react";

const GenderButtonGroups = function (props) {
    let [selectedGender, setSelectedGender] = useState({});

    useEffect(() => {
        if (props.initialGender !== null) {
            setSelectedGender(props.initialGender);
        }
        console.log(props.initialGender)

    }, [props.initialGender]);


    console.log({ initialGender: props.initialGender });

    let switchDisplay = (event) => {
        const gender = new Gender(event.currentTarget.id);
        if (event !== undefined)
            setSelectedGender(gender);
        if (props.setGender !== null)
            props.setGender(gender);

    };

    const aboutGroupOfKids = props.groupVerbage === undefined || props.groupVerbage === true;

    return (
        <div>
            <ButtonGroup className="mb-3">
                <Button theme={selectedGender.id === Gender.boys ? "primary" : "white"} id={Gender.boys} onClick={(e) => { switchDisplay(e) }}>{aboutGroupOfKids ? "Boys" : "Boy"}</Button>
                <Button theme={selectedGender.id === Gender.girls ? "primary" : "white"} id={Gender.girls} onClick={(e) => { switchDisplay(e) }}>{aboutGroupOfKids ? "Girls" : "Girl"}</Button>

                {aboutGroupOfKids ? <Button theme={selectedGender.id === Gender.mixed ? "primary" : "white"} id={Gender.mixed} onClick={(e) => { switchDisplay(e) }}>Combined</Button> : ""}
            </ButtonGroup>
        </div>
    );
}

export default GenderButtonGroups;