import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Alert

} from "shards-react";
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import FirestoreModel from '../../firestoreModel'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

function mapStateToProps(state) {

  return ({
    userAuth: state.userAuth
  });
}

function mapDispatchToProps(dispatch) {

  return {
    onSignIn: (userAuth) => {
      dispatch({ type: 'SIGNED_IN', userAuth });
    },
    onSignOut: (userAuth) => {
      dispatch({ type: 'SIGNED_OUT', userAuth });
    }
  };
}

const UserAccount = connect(mapStateToProps, mapDispatchToProps)(function (props) {

  let [profileState, setProfileState] = useState({ fePassword: '', feEmail: '', feConfirmPassword: '', feUserName: '' });
  let [userStatus, setUserStatus] = useState({});
  let [registerOpen, setRegisterOpen] = useState({ open: false });
  let [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const fireModel = new FirestoreModel();
    let auth = fireModel.firebase.auth();
    fireModel.firebase.firestore();

    auth.onAuthStateChanged(userAuth => {

      setUserStatus({ user: userAuth });
      if (userAuth !== null) {

        // User is authenticated...get the associated user record from the db
        let userDetails = null;
        Promise.all(
          [
            userDetails = fireModel.getUser(userAuth.uid)
          ]
        ).then((values) => {
          userAuth.UserDetails = values[0];
          props.onSignIn(userAuth);
          setProfileState({ fePassword: '', feEmail: userAuth.email, feConfirmPassword: '', feUserName: '' })
        });

        console.log({ userDetails });

      }
      else {
        setProfileState({ fePassword: '', feEmail: '', feConfirmPassword: '', feUserName: '' })
      }


    })
  }, []);

  let closeAlert = (event) => {
    setAlertMessage('');
  }

  let registerUserOpen = (args) => {
    console.log({ a: profileState });

    let newCreds = { ...profileState }
    newCreds.feUserName = ''
    newCreds.feConfirmPassword = ''

    console.log({ b: newCreds });

    setProfileState(newCreds);

    console.log({ c: profileState });

    setRegisterOpen({ open: true });
  }

  let registerUser = (args) => {

    if (profileState.fePassword !== profileState.feConfirmPassword) {
      setAlertMessage("Passwords Do Not Match");
      return;
    }

    firebase.auth().createUserWithEmailAndPassword(profileState.feEmail, profileState.fePassword)
      .then((userCredential) => {
        // Signed in 
        var user = userCredential.user;
        // ...



        let newUser = {
          ClubId: "1",
          Email: profileState.feEmail,
          IsCoach: true,
          ProfileName: profileState.feUserName,
          Role: "Coach",
          UserId: user.uid
        };

        const fireModel = new FirestoreModel();

        fireModel.addUser(newUser);

        alert("User created welcome.")

        setRegisterOpen({ open: false });
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setAlertMessage(error.message)
        // ..
      });



  }


  let signIn = (args) => {

    if (userStatus.user === null || userStatus.user.uid === null) {
      firebase.auth().signInWithEmailAndPassword(profileState.feEmail, profileState.fePassword).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // [START_EXCLUDE]
        if (errorCode === 'auth/wrong-password') {
          alert('Wrong password.');
        } else {
          setAlertMessage(error.message)
          alert(errorMessage);
        }
      });

    }
    else {
      props.onSignOut();
    }


    return '';
  }


  let resetPwd = (args) => {

    if (userStatus.user !== null)
      props.onSignOut();

    firebase.auth().sendPasswordResetEmail(profileState.feEmail)
      .then(() => {
        // Password reset email sent!
        // ..
        setAlertMessage('Password sent.  If not found please check spam folder.')
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setAlertMessage(error.message)
        // ..
      });

    return '';
  }





  let handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    let newCreds = { fePassword: profileState.fePassword, feEmail: profileState.feEmail, feUserName: profileState.feUserName, feConfirmPassword: profileState.feConfirmPassword }
    newCreds[event.target.id] = value;
    setProfileState(newCreds);
  }


  const title = 'Sign-In';
  let signedIn = !(userStatus.user === undefined || userStatus.user === null)

  if (signedIn) {
    if (userStatus.user.uid === null)
      signedIn = false;
  }

  console.log({ profileState })

  console.log({ willBeDiabled: profileState.feEmail.length === 0 || registerOpen.open })

  if (signedIn === false) {
    return (
      <React.Fragment>
        <Alert className="fade mb-0 alert-info" dismissible={(e) => { closeAlert() }} open={alertMessage !== ''} >
          <i className="fa fa-info mx-2"></i>{alertMessage}
        </Alert>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">{title}</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form>
                    <Row form>
                      {/* Email */}
                      <Col md="6" className="form-group">
                        <label htmlFor="feEmail">Email</label>
                        <FormInput
                          type="email"
                          id="feEmail"
                          placeholder="Email Address"
                          value={profileState.feEmail}
                          onChange={handleChange}
                        />
                      </Col>
                      {/* Password */}
                      <Col md="6" className="form-group">
                        <label htmlFor="fePassword">Password</label>
                        <FormInput
                          type="password"
                          id="fePassword"
                          placeholder="Password"
                          value={profileState.fePassword}
                          onChange={handleChange}
                        />
                        <br></br>
                      </Col>
                    </Row>
                    <Row form>
                      <Col className="text-right">
                        <Button size="sm" theme="primary" className="mb-2 mr-1" onClick={(event) => { signIn(event) }}>{signedIn ? "Sign Out" : "Sign In"}</Button>
                        <Button size="sm" theme="primary" className="mb-2 mr-1" disabled={profileState.feEmail.length === 0} onClick={(event) => { resetPwd(event) }}>Forgot Password</Button>
                        <Button size="sm" theme="primary" className="mb-2 mr-1" disabled={profileState.feEmail.length === 0 || registerOpen.open} onClick={(event) => { registerUserOpen(event) }}>Register</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup >
        </Card >
        {registerOpen.open === true ?
          <React.Fragment>
            <Alert className="fade mb-0 alert-info" dismissible={(e) => { closeAlert() }} open={alertMessage !== ''} >
              <i className="fa fa-info mx-2"></i>{alertMessage}
            </Alert>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Register</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form autoComplete="off">
                        <Row form>
                          {/* Password */}
                          <Col md="6" className="form-group">
                            <label htmlFor="feConfirmPassword">Confirm Password</label>
                            <FormInput
                              type="password"
                              id="feConfirmPassword"
                              placeholder="Password"
                              value={profileState.feConfirmPassword}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md="6" className="form-group">
                            <label htmlFor="feUserName">User Name</label>
                            <FormInput
                              type="text"
                              id="feUserName"
                              placeholder="User name"
                              value={profileState.feUserName}
                              onChange={handleChange}
                            />
                          </Col>
                        </Row>
                        <Row form>
                          <Col className="text-right">
                            <Button size="sm" theme="primary" className="mb-2 mr-1" disabled={profileState.feEmail.length === 0 || !profileState.feUserName || profileState.feConfirmPassword.length === 0} onClick={(event) => { registerUser(event) }}>Register</Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup >
            </Card >
          </React.Fragment>
          :
          ""
        }

      </React.Fragment>
    );
  }
  else
    return null;
  /*
     return (
       <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">{title}</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form>
                    <Row form>
                      <Col>
                        <Button outline size="sm" theme="primary" className="mb-2 mr-1" onClick={(event) => { signIn(event) }}>{signedIn ? "Sign Out" : "Sign In"}</Button>
                        <Button outline size="sm" theme="primary" className="mb-2 mr-1">Validate Email</Button>
                      </Col>

                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup >
        </Card >
        );
        */
}
);

export default UserAccount;
