import React from "react";
import { Container, Row, Col } from "shards-react";
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

const Tutorial = () => (
    <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
            <PageTitle title="Tutorial" subtitle="Learn how to install and use the app" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
            <Col lg="4">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Setting Up</h4>

                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <p>Account creation, first Sign-in and team creation</p>
                            <iframe height="315" src="https://www.youtube.com/embed/ncL1BTF2jgY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="4">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Roster</h4>

                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <p>Updating your roster</p>
                            <iframe height="315" src="https://www.youtube.com/embed/evxCijN4vFo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="4">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Features</h4>

                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <p>Learn some of the advanced features of the app</p>
                            <iframe height="315" src="https://www.youtube.com/embed/C5ZrJqorsKM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        </Row>
    </Container >
);

export default Tutorial;

