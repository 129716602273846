import React from "react";
import { Container, Row, Col } from "shards-react";
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

const Welcome = () => (
    <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
            <PageTitle title="Welcome" subtitle="Youth Track Manager" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Welcome to Youth Track Manager</h4>

                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <p className="text-muted d-block mb-2">This site and app are targetted at Youth Track teams who are looking to reduce the administration surrounding the meet day. The application provides online athlete sign-up at any time and produces sign-up grids and sheets.</p>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <img
                            src={require("../images/content-management/welcome.png")}
                            alt="welcome"
                            width="100%"
                        />
                    </CardHeader>
                </Card>
            </Col>
        </Row>
    </Container >
);

export default Welcome;
