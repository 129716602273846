import React, { useState, useEffect } from "react";
import {
    Card,
    CardFooter,
    Button,
    CardHeader,
    CardBody,
    Alert,
    Container,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form
} from "shards-react";
import FirestoreModel from '../firestoreModel'
import { connect } from 'react-redux'

import Athlete from "../utils/athlete";
import AthleteDetails from "./AthleteDetail";

function mapStateToProps(state) {
    console.log({ state: state });
    return ({
        userAuth: state.userAuth,
        selectedTeam: state.selectedTeam
    });
}

function mapDispatchToProps(dispatch) {
    return {
        onSignIn: (userAuth) => {
            dispatch({ type: 'SIGNED_IN', userAuth });
        },
        onSignOut: (userAuth) => {
            dispatch({ type: 'SIGNED_OUT', userAuth });
        }
    };
}

const AthleteAdmin = connect(mapStateToProps, mapDispatchToProps)(function (props) {
    let [athleteList, setAthleteList] = useState([]);
    let [athleteHighlighted, setAthleteHighlighted] = useState({ athlete: null, changesDetected: null });
    let [alertMessage, setAlertMessage] = useState('');
    let [completeTeam, setCompleteTeam] = useState({});

    useEffect(() => {
        console.log({ propCheck: props.selectedTeam })
        if (props.selectedTeam === undefined || props.selectedTeam === null) {
            return;
        }

        const fireModel = new FirestoreModel();

        Promise.all(
            [
                fireModel.fetchPrivateTeamList(props.selectedTeam.Id),
                fireModel.fetchTeamAsync(props.selectedTeam.Id)
            ]
        ).then((values) => {
            setAthleteList(values[0]);
            setCompleteTeam(values[1]);
        })
            .catch((ex) => {
                setAlertMessage(ex);
            })

    }, [props.selectedTeam]);

    let handleAthleteChange = (athlete) => {
        console.log(athlete, athleteHighlighted, athlete === athleteHighlighted.athlete)
        if (athleteHighlighted.athlete !== null && athleteHighlighted.athlete.AthleteId === athlete.AthleteId)
            setAthleteHighlighted(Object.assign({}, athleteHighlighted, { athlete: null, changesDetected: null }));
        else
            setAthleteHighlighted(Object.assign({}, athleteHighlighted, { athlete: athlete, changesDetected: null }));
    }

    let onSaveAthlete = (athlete) => {
        // Need to handle new athletes
        // New athletes have a blank id, but it is unique.  
        // if id is blank do an add, if exists do a put
        let found = athleteList.find((e) => { return e.AthleteId === athlete.AthleteId });
        if (found) {
            found = athlete;
            const fireModel = new FirestoreModel();

            console.log({ athleteUpdating: athlete });

            Promise.all(
                [
                    fireModel.putAthlete(athlete, props.selectedTeam.Id)
                ]
            ).then((values) => {
                if (athlete.AthleteId === '')
                    athlete.AthleteId = athlete.id = values[0].AthleteId;
            })
                .catch((ex) => {

                    setAlertMessage(ex.message);
                    throw (ex);
                })

            let newList = athleteList.map((e) => {
                if (e.AthleteId === athlete.AthleteId) {
                    return { ...athlete.rawObject(props.selectedTeam.Id), id: e.AthleteId }
                }
                else
                    return e;
            })

            setAthleteList(newList);
            setAlertMessage("Athlete data saved.")
        }
    }

    let onDeleteAthlete = (athlete) => {
        let found = athleteList.find((e) => { return e.AthleteId === athlete.AthleteId });
        if (found) {
            found = athlete;
            const fireModel = new FirestoreModel();

            console.log({ athleteUpdating: athlete });

            Promise.all(
                [
                    fireModel.delAthlete(athlete, props.selectedTeam.Id)
                ]
            ).catch((ex) => {

                setAlertMessage(ex.message);
                throw (ex);
            })

            //let newList = athleteList.map((e) => {
            //    if (e.AthleteId === athlete.AthleteId) {
            //        return { ...athlete.rawObject(props.selectedTeam.Id), id: e.AthleteId }
            //    }
            //    else
            //        return e;
            //})

            let newList = athleteList.filter((e) => e.AthleteId !== athlete.AthleteId)

            setAthleteList(newList);
            setAlertMessage("Athlete data saved.")
        }
    }



    let closeAlert = (event) => {
        setAlertMessage('');
    }

    let addAthlete = () => {
        let found = athleteList.find((e) => { return e.AthleteId === "" });
        if (found !== undefined) {
            setAlertMessage("Please save the previous new athlete before adding more.")
        }
        else {
            const newAthlete = new Athlete();
            newAthlete.Name = "New Athlete";

            let newList = athleteList.map((e) => {
                return e;
            })
            newList.push(newAthlete);

            setAthleteList(newList);

            setAthleteHighlighted(Object.assign({}, athleteHighlighted, { athlete: newAthlete, changesDetected: null }));

        }
    }


    if (athleteList === null || athleteList === undefined)
        return "";
    else {
        return (
            <React.Fragment>
                <Container fluid className="px-0">
                    <Alert className="fade mb-0 alert-info" dismissible={(e) => { closeAlert() }} open={alertMessage !== ''} >
                        <i className="fa fa-info mx-2"></i>{alertMessage}
                    </Alert>
                </Container>
                <Container fluid className="main-content-container px-4">
                    <Card>
                        <CardHeader className="border-bottom">
                            <h6 className="m-0">All Athletes</h6>
                        </CardHeader>
                        <CardBody>
                            <table className="table mb-0" >
                                <thead className="bg-light">
                                    <tr>
                                        <th scope="col" className="border-0">Name</th>
                                        <th scope="col" className="border-0">AlsoKnownAs</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        athleteList.map((athleteObject) => {
                                            const athlete = new Athlete();
                                            athlete.CopyFromObject(athleteObject);
                                            const openedUp = athleteHighlighted.athlete !== null && athlete.AthleteId === athleteHighlighted.athlete.AthleteId;
                                            return (<React.Fragment>
                                                <tr key={athlete.Name + athlete.AthleteId}
                                                    className={openedUp ? "bg-light" : ""}
                                                    onClick={(event) => { handleAthleteChange(athlete) }}>
                                                    <td>{athlete.Name}</td>
                                                    <td>{athlete.AlsoKnownAs}</td>
                                                </tr>
                                                {openedUp ? <tr><td colSpan={2}><AthleteDetails athlete={athlete} onSaveAthlete={onSaveAthlete} ageGroups={completeTeam.Divisions} onDeleteAthelete={onDeleteAthlete} /></td></tr> : ""}
                                            </React.Fragment>
                                            )
                                        }
                                        )
                                    }
                                </tbody>

                            </table>
                        </CardBody>
                        <CardFooter className="border-top">
                            <Alert className="fade mb-0 alert-info" dismissible={(e) => { closeAlert() }} open={alertMessage !== ''} >
                                <i className="fa fa-info mx-2"></i>{alertMessage}
                            </Alert>
                            <Button onClick={addAthlete}>Add a New Athlete</Button>
                        </CardFooter>
                    </Card>
                </Container>
            </React.Fragment>
        )
    }
}
)

export default AthleteAdmin;