import React, { useState, useEffect, useRef } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button
} from "shards-react";
import FirestoreModel from '../firestoreModel'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

function mapStateToProps(state) {

    // have to make a new oboject, otherwise component will not re-render
    let obj = {
        ...state
    };
    obj.selectedTeam = state.selectedTeam;
    obj.userAuth = state.userAuth;

    return obj;

    /*return ({
        userAuth: state.userAuth,
        selectedTeam: state.selectedTeam
    });*/
}

function mapDispatchToProps(dispatch) {

    return {
        onSetTeam: (Team) => {
            dispatch({ type: 'TEAM_SET', Team });
        },
    };

}



const MyTeams = connect(mapStateToProps, mapDispatchToProps)(function (props) {
    let [userTeams, setUserTeams] = useState({});
    let [userFound, setUserFound] = useState({ found: false });
    let [savedPrimaryTeam, setSavedPrimaryTeam] = useState({});

    let [clubs, setClubs] = useState({});

    let handleTeamChange = (team) => {
        props.onSetTeam(team);
    }

    let handlePrimaryTeamChange = (team) => {

        if (window.confirm(team.Name + " will become your primary team. This team's meets and signups will be opened first each time you log in, is this ok?")) {
            localStorage.setItem('dataKey', JSON.stringify(team.Id));
            setSavedPrimaryTeam(team.Id);
            const fireModel = new FirestoreModel();
            const obj = props.userAuth.UserDetails;
            fireModel.putUser(obj.Id, '', '', team.Id);
        }

    }

    let handleReject = async (team) => {
        if (window.confirm("Click Ok to reject this position.  Your invitation will be deleted for team:  " + team.Name)) {
            const fireModel = new FirestoreModel();

            const currentUser = props.userAuth.UserDetails;
            if (currentUser.ProfileTeams === undefined)
                currentUser.ProfileTeams = [];

            const newUserTeam = { ...userTeams }
            await fireModel.acceptUserInvites(team, currentUser, false)
            newUserTeam.ProfileTeams = newUserTeam.ProfileTeams.filter((e) => e.Id !== team.Id);
            setUserTeams(newUserTeam);
            props.onSetTeam()
        }
    }



    let handleAccept = async (team) => {

        if (window.confirm("Click Ok to accept the invitation to become a coach of " + team.Name)) {
            const obj = {
                ClubId: team.ClubId,
                ClubName: team.ClubName,
                Id: team.Id,
                Name: team.Name,
                NumberOfAthletes: 0,
                Role: "Coach"
            };

            const newUserTeam = { ...userTeams }

            // update userTeams to set the accepted to full membership




            const fireModel = new FirestoreModel();
            const foundClub = await fireModel.fetchClubsAsync(team.ClubId);

            if (foundClub)
                obj.ClubName = foundClub[0].Name;


            const currentUser = props.userAuth.UserDetails;
            if (currentUser.ProfileTeams === undefined)
                currentUser.ProfileTeams = [];
            currentUser.ProfileTeams.push(obj);

            fireModel.putCompleteUser(currentUser);


            const newTeam = await fireModel.acceptUserInvites(team, currentUser, true)

            console.log(newUserTeam.ProfileTeams)
            newUserTeam.ProfileTeams = newUserTeam.ProfileTeams.filter((e) => e.Id !== team.Id);
            newTeam.Role = "Coach";

            newUserTeam.ProfileTeams.push(newTeam);
            setUserTeams(newUserTeam);



        }

    }


    useEffect(() => {
        setSavedPrimaryTeam(localStorage.getItem('dataKey') !== undefined ? JSON.parse(localStorage.getItem('dataKey')) : null);

        if (props.userAuth === undefined || props.userAuth === null) {
            userTeams.UserId = undefined;
            return;
        }

        const fireModel = new FirestoreModel();

        Promise.all(
            [
                fireModel.fetchUserTeamsAsync(props.userAuth.uid),
                fireModel.getUserInvites(props.userAuth.uid, props.userAuth.email)

            ]
        ).then((values) => {
            if ((values[0] !== undefined && values[0] !== null)) {
                if (values[1] !== null) {
                    if (values[0].ProfileTeams === undefined)
                        values[0].ProfileTeams = [];

                    values[1].forEach((e) => {
                        e.Role = "Pending";
                        values[0].ProfileTeams.push(e)
                    });
                }
                setUserTeams(values[0]);
                setUserFound({ found: true });
                setSavedPrimaryTeam(props.userAuth.UserDetails.TeamId);
            }
            else
                setUserFound({ found: false });


        })

    }, [props.userAuth]);

    const isTeamPicked = !(props.selectedTeam === null || props.selectedTeam === undefined)
    let teamToPick = null

    if (isTeamPicked === false) {
        if (!(userTeams === null || userTeams.ProfileTeams === undefined)) {
            if (savedPrimaryTeam !== null) {
                // no team is selected, but there is a saved primary team, make that selected
                const foundTeam = userTeams.ProfileTeams.find(team => team.Id === savedPrimaryTeam);
                if (foundTeam !== undefined) {
                    teamToPick = foundTeam.Id;
                    props.onSetTeam(foundTeam);
                }
            }
            else {
                // Check the stored primary team
                if (props.userAuth.UserDetails.TeamId !== null && props.userAuth.UserDetails.TeamId !== undefined) {
                    const foundTeam = userTeams.ProfileTeams.find(team => team.Id === props.userAuth.UserDetails.TeamId);
                    if (foundTeam !== undefined) {
                        teamToPick = foundTeam.Id;
                        props.onSetTeam(foundTeam);
                    }

                }
                else if (userTeams.ProfileTeams.length === 1) {
                    teamToPick = userTeams.ProfileTeams[0].Id;
                    props.onSetTeam(userTeams.ProfileTeams[0]);

                }

            }
        }

    }
    else {
        teamToPick = props.selectedTeam.Id;

    }

    //<tr key={team.Id} className={team.Id !== undefined && props.selectedTeam !== undefined && team.Id === props.selectedTeam.Id ? "bg-light" : ""} onClick={(event) => handleTeamChange(team)}>
    if (props.userAuth === null)
        return (
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">My Teams</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                    <p className="text-center">
                        Please sign-in
                    </p>
                </CardBody>
            </Card>

        )
    else if (userFound.found === false)
        return (
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">My Teams</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                    <p className="text-center">
                        Loading...
                    </p>
                </CardBody>
            </Card>

        )
    else if ((userTeams.ProfileTeams === null || userTeams.ProfileTeams === undefined) && userFound.found === true)
        return (
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">My Teams</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                    <p className="text-center">
                        Accept an invite or create a new team.
                    </p>
                </CardBody>
            </Card>

        )
    else
        return (
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">My Teams</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                        <thead className="bg-light">
                            <tr>
                                <th scope="col" className="border-0">Team Name</th>
                                <th scope="col" className="border-0">Role</th>
                                <th scope="col" className="border-0">Club</th>
                                <th scope="col" className="border-0 text-center">Primary</th>
                                <th scope="col" className="border-0 text-center">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userTeams.ProfileTeams.map((team) => {
                                    return (
                                        <tr key={team.Id} className={team.Id !== undefined && team.Id === teamToPick ? "bg-light" : ""} onClick={(event) => handleTeamChange(team)}>
                                            <td className={team.Id !== undefined && team.Id === teamToPick ? "text-success" : ""}>{team.Name}</td>
                                            <td>{team.Role}</td>
                                            <td>{team.ClubName}</td>
                                            <td className="text-center">
                                                <img
                                                    id="main-logo"
                                                    className="d-inline-block align-top mr-1"
                                                    style={{ maxWidth: "25px" }}

                                                    src={
                                                        team.Id !== undefined && savedPrimaryTeam !== undefined && team.Id === savedPrimaryTeam ? require("../images/crossenabled.png") : require("../images/crossdisabled.png")}
                                                    alt="Team list"
                                                    onClick={(event) => { handlePrimaryTeamChange(team) }}
                                                />
                                            </td>
                                            {team.Role !== "Pending" ? <td><Link to={{ pathname: "teamdetails", state: "join" }}><Button className="mb-2 mr-1 text-center" type="button" theme="white">...</Button></Link></td> :
                                                <td><Button className="btn btn-accent form-check-inline text-center" type="button" onClick={(event) => handleAccept(team)} >Accept</Button><Button className="btn btn-accent text-center" type="button" onClick={(event) => handleReject(team)} >Reject</Button> </td>
                                            }
                                        </tr>
                                    )

                                })
                            }
                        </tbody>
                    </table>
                </CardBody>
                <CardBody className="p-0 pb-3">
                    <Row>
                        <Col className="text-center">
                            {isTeamPicked === true ? <Link to={{ pathname: "manageteam", state: "import" }}><Button className="btn btn-accent" type="button" >Import Athletes</Button></Link> : ""}
                        </Col>
                        <Col className="text-center">
                            {isTeamPicked === true ? <Link to={{ pathname: "manageteam", state: "export" }}><Button className="btn btn-accent" type="button" >Export Athletes</Button></Link> : ""}
                        </Col>
                        <Col className="text-center">
                            <Link to={{ pathname: "teamdetails", state: "add" }}><Button className="btn btn-accent" type="button" >New Team</Button></Link>
                        </Col>
                        <Col className="text-center">
                            {isTeamPicked === true ? <Link to={{ pathname: "athleteadmin", state: "add" }}><Button className="btn btn-accent" type="button" >Athletes</Button></Link> : ""}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
}
)
export default MyTeams;
