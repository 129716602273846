import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form

} from "shards-react";
import FirestoreModel from '../firestoreModel'
import { connect } from 'react-redux'
import AthleteTable from "./AthleteTable";

function mapStateToProps(state) {
    console.log({ state: state });
    return ({
        userAuth: state.userAuth,
        selectedTeam: state.selectedTeam
    });
}

function mapDispatchToProps(dispatch) {

    return {
        onSignIn: (userAuth) => {
            dispatch({ type: 'SIGNED_IN', userAuth });
        },
        onSignOut: (userAuth) => {
            dispatch({ type: 'SIGNED_OUT', userAuth });
        }
    };
}

const Athletes = connect(mapStateToProps, mapDispatchToProps)(function (props) {
    let [athleteList, setAthleteList] = useState({});
    let [pending, setPending] = useState(false);

    useEffect(() => {
        if (props.selectedTeam === undefined || props.selectedTeam === null) {
            return;
        }

        const fireModel = new FirestoreModel();

        Promise.all(
            [
                fireModel.fetchPrivateTeamList(props.selectedTeam.Id)
            ]
        ).then((values) => {
            setAthleteList(values[0]);
        })
            .catch((ex) => {
                if (ex.message === "Missing or insufficient permissions.")
                    setPending(true);
                else
                    throw (ex);
            })

    }, [props.selectedTeam]);

    const title = props.selectedTeam === null || props.selectedTeam === undefined ? "Team Details" : "Team Details - " + props.selectedTeam.Name;
    let preLoadMessage = "";

    if (props.userAuth === null || props.userAuth === undefined)
        return "Sign In";

    if (athleteList.length === undefined) {
        if (pending === true)
            preLoadMessage = "You can not see athletes until you accept the invitation.";
        else
            preLoadMessage = "Loading....";
    }

    else if (athleteList.length === 0)
        preLoadMessage = "No Athletes";


    if (props.selectedTeam === undefined || props.selectedTeam === null)
        return "";

    if (props.selectedTeam === undefined || props.selectedTeam === null || athleteList.length === undefined) {
        return (<Card small className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">{title}</h6>
            </CardHeader>
            <ListGroup flush>
                <ListGroupItem className="p-3">
                    <Row>
                        <Col>
                            <Form>
                                <Row form>
                                    <Col>
                                        {preLoadMessage}
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </ListGroupItem>
            </ListGroup >
        </Card>);
    }
    else {
        return (<Card small className="mb-4">
            <CardHeader className="border-bottom">
                <h6 className="m-0">{title}</h6>
            </CardHeader>

            <CardBody className="p-0 pb-3">
                <AthleteTable athleteList={athleteList} smallSet={false} />
            </CardBody>


        </Card>);
    }

}
)
export default Athletes;
