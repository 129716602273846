import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Container,
    CardBody,
    FormInput,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    FormFeedback,
    Alert,
    DatePicker,
    FormSelect

} from "shards-react";

import FirestoreModel from '../firestoreModel'
import { connect } from 'react-redux'


function mapStateToProps(state) {
    return ({
        userAuth: state.userAuth,
        selectedTeam: state.selectedTeam,
        selectedMeet: state.selectedMeet
    });
}

function mapDispatchToProps(dispatch) {

    return {
        onSignIn: (userAuth) => {
            dispatch({ type: 'SIGNED_IN', userAuth });
        },
        onSignOut: (userAuth) => {
            dispatch({ type: 'SIGNED_OUT', userAuth });
        }
    };
}

const MeetDetails = connect(mapStateToProps, mapDispatchToProps)(function (props) {
    // do we need to know if this is a new team?

    const addingMeet = (props.location.state.action === 'add');

    let [inEditMode, setInEditMode] = useState({});
    let [meetName, setMeetName] = useState(addingMeet ? "" : props.selectedMeet.Name);
    let [meetDate, setMeetDate] = useState({});
    let [startTime, setStartTime] = useState(addingMeet ? "8:00am" : props.selectedMeet.StartTime);
    let [alertMessage, setAlertMessage] = useState('');
    let [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setMeetName(addingMeet ? "" : props.selectedMeet.Name);
        const fireModel = new FirestoreModel();
        let tempDate = new Date();
        if (!addingMeet)
            tempDate = fireModel.getDateFromDateStr(props.selectedMeet.Date);
        setMeetDate(addingMeet ? new Date() : tempDate)

    }, [props.selectedMeet]);




    let closeAlert = (event) => {
        setAlertMessage('');
    }

    let handleNameChange = (event) => {
        setIsDirty(true);
        setMeetName(event.target.value);
    }

    let handleDateChange = (event) => {
        setIsDirty(true);
        console.log(event);
        setMeetDate(event);

    }

    let handleDStartTimeChange = (event) => {
        setIsDirty(true);
        setStartTime(event.target.value);
    }

    let deleteMeet = (event) => {

    }

    let cancel = (event) => {

    }

    let saveData = async () => {
        if (meetDate === null) {
            setAlertMessage("Please specify a date");
            return;
        }

        try {
            const checkDate = new Date();
            checkDate.Date = `${meetDate.getFullYear()}-${(meetDate.getMonth() + 1).toString().padStart(2, "0")}-${(meetDate.getDate() + 0).toString().padStart(2, "0")}`;
        }
        catch (e) {
            setAlertMessage("Invalid date");
            return;
        }


        if (isDirty) {
            const obj = {}
            obj.Name = meetName;

            obj.Date = `${meetDate.getFullYear()}-${(meetDate.getMonth() + 1).toString().padStart(2, "0")}-${(meetDate.getDate() + 0).toString().padStart(2, "0")}`;
            obj.StartTime = startTime

            const fireModel = new FirestoreModel();

            // perhaps set the selected team ????
            if (addingMeet && obj.Id == null) {
                obj.Clubs = []
                obj.Clubs.push(props.location.state.club)

                await fireModel.addMeet(obj, props.userAuth.UserDetails);
            }
            else {
                obj.MeetId = props.selectedMeet.MeetId;
                await fireModel.putMeet(obj);
            }


            setAlertMessage("Meet Updated");
            setIsDirty(false);
        }
        else
            setAlertMessage("Nothing to update");

    }

    const title = addingMeet ? "New Meet" : props.selectedMeet.Name;

    if ((props.selectedMeet === null || props.selectedMeet === undefined) && !addingMeet)
        return "Select a meet";
    return (<div><Container fluid className="px-0">
        <Alert className="fade mb-0 alert-info" dismissible={(e) => { closeAlert() }} open={alertMessage !== ''} >
            <i className="fa fa-info mx-2"></i>{alertMessage}
        </Alert>
    </Container>
        <Container fluid className="main-content-container px-4">
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">{title}</h6>
                </CardHeader>
                <ListGroup flush>
                    <ListGroupItem className="p-3">
                        <Row>
                            <Col>
                                <Form>
                                    <Row>
                                        <Col md="6" >
                                            <FormGroup>
                                                <label htmlFor="feMeetName">Team Name</label>
                                                <FormInput className="invalid"
                                                    required
                                                    type="text"
                                                    id="feMeetName"
                                                    placeholder="Enter Meet name"
                                                    value={meetName}
                                                    onChange={handleNameChange}
                                                    valid={meetName.trim().length > 5}
                                                    invalid={meetName.trim().length <= 5}
                                                />
                                                <FormFeedback invalid>Meet  name must be longer than 5 characters.</FormFeedback>
                                                <FormFeedback valid>Valid</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" >
                                            <Row>
                                                <label htmlFor="feDate">Date (YYYY-MM-DD)</label>
                                            </Row>
                                            <Row>
                                                <FormGroup>
                                                    <DatePicker
                                                        id="feDate"
                                                        selected={meetDate}
                                                        onChange={handleDateChange}
                                                    />
                                                </FormGroup>
                                            </Row>
                                        </Col>
                                        <Col md="3" >
                                            <FormGroup>
                                                <label htmlFor="feStartTime">Start Time</label>
                                                <FormInput
                                                    type="label"
                                                    id="feStartTime"
                                                    value={startTime}
                                                    onChange={handleDStartTimeChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-center" md="11">
                                            <Button outline size="sm" theme="primary" className="mb-2 mr-1" onClick={(event) => { saveData() }}>{addingMeet ? "Create" : "Update"}</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ListGroup >
            </Card>
        </Container></div>);
}


)
export default MeetDetails;
