import React, { useState, useEffect } from "react";
import FirestoreModel from '../../firestoreModel'
import { Link } from 'react-router-dom';
import {
    Card,
    CardHeader,
    Container,
    CardBody,
    FormInput,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Button,
    Form

} from "shards-react";


const OptionalTextCollector = function (props) {
    let [dataCollected, setDataCollected] = useState(props.defaultValue === undefined ? '' : props.defaultValue);
    let [inConfirm, setinConfirm] = useState(false);

    let switchDisplay = (event) => {
        setDataCollected(event.target.value);
    };

    let click = (event) => {
        if (event === 'Cancel')
            setinConfirm(false);
        else {
            if (inConfirm === true)
                props.dataUpdate(dataCollected);
            setinConfirm(!inConfirm);
        }

    };

    return (<table style={{ width: '100%' }}>
        <tbody>
            <tr >
                <td style={{ width: '80%' }}>
                    {inConfirm ?
                        <FormInput
                            type="label"
                            id="feOwnerName"
                            value={dataCollected}
                            onChange={switchDisplay}
                            placeholder="Enter email address"
                        /> : ''}
                </td>
                <td style={{ width: '20%' }}>
                    <Button outline size="sm" theme="primary" className="mb-2 mr-1" onClick={(event) => { click(event) }}>{inConfirm ? props.confirmLabel : props.startLabel}</Button>
                    {inConfirm ? <Button outline size="sm" theme="primary" className="mb-2 mr-1" onClick={(event) => { click('Cancel') }}>{props.cancelLabel}</Button> : ""}
                </td>
            </tr>
        </tbody>
    </table>
    )

}

export default OptionalTextCollector;