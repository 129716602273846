import React, { useState, useEffect } from "react";
import { Nav, NavLink, Badge, NavItem, DropdownItem, Collapse } from "shards-react";

import { connect } from 'react-redux'

import FirestoreModel from '../../../../firestoreModel'

function mapStateToProps(state) {
  console.log({ state: state });
  return ({
    userAuth: state.userAuth,
    selectedTeam: state.selectedTeam
  });
}

function mapDispatchToProps(dispatch) {

  return {
    onSignIn: (userAuth) => {
      dispatch({ type: 'SIGNED_IN', userAuth });
    },
    onSignOut: (userAuth) => {
      dispatch({ type: 'SIGNED_OUT', userAuth });
    }
  };
}

const Notifications = connect(mapStateToProps, mapDispatchToProps)(function (props) {
  let [state, setState] = useState({ visible: false });

  let toggleNotifications = (event) => {
    setState({
      visible: !state.visible
    });
  }

  console.log({ a: props.teamInvites })

  let numberOfAlerts = props.teamInvites == null ? 0 : props.teamInvites.length;

  const emailNotVerified = props.userAuth != null && props.userAuth.emailVerified === false;
  if (emailNotVerified === true) {
    numberOfAlerts++
  }


  return (
    <NavItem className="border-right dropdown notifications">
      <NavLink
        className="nav-link-icon text-center"
        onClick={toggleNotifications}
      >
        <div className="nav-link-icon__wrapper">
          <i className="material-icons">&#xE7F4;</i>
          <Badge pill theme="danger">
            {numberOfAlerts === 0 ? "" : numberOfAlerts}
          </Badge>
        </div>
      </NavLink>
      <Collapse
        open={state.visible}
        className="dropdown-menu dropdown-menu-small">
        {props.teamInvites == null ? '' : props.teamInvites.map((e) => {
          return <DropdownItem key={e.Id} onClick={toggleNotifications}>
            <div className="notification__icon-wrapper">
              <div className="notification__icon">
                <i className="material-icons">&#xE6E1;</i>
              </div>
            </div>
            <div className="notification__content">
              <span className="notification__category text-bold">{e.Name}</span>
              <p>
                You have been invited to join the team.  Please click
                here to accept the invitation.
              </p>
            </div>
          </DropdownItem>
        })}
        {emailNotVerified === true ?
          <DropdownItem key="emailCheck" onClick={toggleNotifications}>
            <div className="notification__icon-wrapper">
              <div className="notification__icon">
                <i className="material-icons">&#xE6E1;</i>
              </div>
            </div>
            <div className="notification__content">
              <span className="notification__category text-bold">EMail Verification Missing</span>
              <p>
                Get complete access by validating your email address.
                Click here to start the process.
              </p>
            </div>
          </DropdownItem> : ""
        }
      </Collapse>
    </NavItem>
  )
}
)

/*
<DropdownItem>
<div className="notification__icon-wrapper">
  <div className="notification__icon">
    <i className="material-icons">&#xE6E1;</i>
  </div>
</div>
<div className="notification__content">
  <span className="notification__category">Contribute</span>
  <p>
    Support the hosting of this web site we are
    <span className="text-success text-semibold">108%</span> volunteer based.
  </p>
</div>
</DropdownItem>
*/

export default Notifications;
