import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Container,
    CardBody,
    FormInput,
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    FormFeedback,
    Alert,
    FormSelect

} from "shards-react";
import AgeGroup from "../utils/agegroup";

import Gender from "../utils/gender"
import FirestoreModel from '../firestoreModel'
import { connect } from 'react-redux'
import GenderButtonGroups from "./TeamDetailComponents/GenderButtonGroups";
import AgeGroups from "./TeamDetailComponents/AgeGroups";
import TeamRoles from "./TeamDetailComponents/TeamRoles";


function mapStateToProps(state) {

    return ({
        userAuth: state.userAuth,
        selectedTeam: state.selectedTeam
    });
}

function mapDispatchToProps(dispatch) {

    return {
        onSignIn: (userAuth) => {
            dispatch({ type: 'SIGNED_IN', userAuth });
        },
        onSignOut: (userAuth) => {
            dispatch({ type: 'SIGNED_OUT', userAuth });
        }
    };
}

const TeamDetails = connect(mapStateToProps, mapDispatchToProps)(function (props) {
    // do we need to know if this is a new team?
    const addingTeam = (props.location.state === 'add');

    let [inEditMode, setInEditMode] = useState({});
    let [teamName, setTeamName] = useState(addingTeam ? "" : props.selectedTeam.Name);
    let [completeTeam, setCompleteTeam] = useState({});
    let [displayedPane, setDisplayedPane] = useState("0");
    let [isDirty, setIsDirty] = useState(false);

    let [newAgeGropupList, setNewAgeGropupList] = useState([]);
    let [newGender, setNewGender] = useState(new Gender(0));
    let [newRoles, setNewRoles] = useState(0);
    let [newStaffList, setNewStaffList] = useState(0);
    let [newAgeEventList, setNewAgeEventList] = useState([]);

    let [alertMessage, setAlertMessage] = useState('');
    let [userDetails, setUserDetails] = useState({});
    let [clubs, setClubs] = useState([]);
    let [selectedClub, setSelectedClub] = useState('');


    //const addingTeam = (props.selectedTeam === undefined || props.selectedTeam === null);

    useEffect(() => {
        setTeamName(addingTeam ? "" : props.selectedTeam.Name);

        const fireModel = new FirestoreModel();

        Promise.all(
            [
                fireModel.fetchClubsAsync()
            ]
        ).then((values) => {
            setClubs(values[0]);
        });

        if (addingTeam === false) {
            Promise.all(
                [
                    fireModel.fetchTeamAsync(props.selectedTeam.Id),
                    fireModel.getUser(props.userAuth.uid)
                ]
            ).then((values) => {
                if (values[0].Name !== props.selectedTeam.Name) {
                    setAlertMessage("Your team name was updated.  Click update to confirm.")
                    setIsDirty(true);
                }

                // We cant guarantee that user will visit each page therefore get the data so it is complete
                // when it is saved
                setTeamName(values[0].Name);
                setCompleteTeam(values[0]);

                setNewGender(new Gender(values[0].Gender));

                setSelectedClub(values[0].ClubId)

                if (values[0].Divisions.length > 0) {
                    let updatedList = values[0].Divisions.map(item => {
                        let ageGroup = new AgeGroup();
                        ageGroup.SetFromObj(item);

                        return ageGroup;
                    });
                    setNewAgeGropupList(updatedList)
                    setNewAgeEventList(values[0].AgeEvents)
                }

                setUserDetails(values[1]);




            })

        }


    }, [props.selectedTeam]);


    let closeAlert = (event) => {
        setAlertMessage('');
    }

    let handleTeamNameChange = (event) => {
        setTeamName(event.target.value);
        const newobj = completeTeam;
        newobj.Name = event.target.value;

        setCompleteTeam(newobj);
        setIsDirty(true);
    }

    // Data collection from child components
    // Gender
    let setGender = (gender) => {
        setNewGender(gender);
        setIsDirty(true);
    }

    // Age List
    let setAgeGroups = (ageGroupList) => {
        setNewAgeGropupList(ageGroupList);
        setIsDirty(true);
    }

    let setRoles = (roles) => {
        setNewRoles(roles);
        setIsDirty(true);
    }

    let setStaffList = (staff) => {
        setNewStaffList(staff);
        setIsDirty(true);
    }

    let setAgeEventList = (ageEventList) => {
        setNewAgeEventList(ageEventList)
        setIsDirty(true);
    }

    let toggleView = (pane) => {
        setDisplayedPane(pane === displayedPane ? "0" : pane);
    }

    let saveData = async () => {
        if (isDirty) {
            const obj = { ...completeTeam }

            if (newGender === undefined) {
                setAlertMessage("Please set the gender of your team.");
                return;
            }

            obj.Gender = newGender.id;

            obj.Divisions = newAgeGropupList.map(item => { return item.getObj(); });
            if (newRoles !== 0)
                obj.Roles = newRoles;
            if (newStaffList !== 0)
                obj.Staff = newStaffList;
            obj.AgeEvents = newAgeEventList;
            obj.Name = teamName;

            console.log({ obj });

            const fireModel = new FirestoreModel();

            // perhaps set the selected team ????
            if (addingTeam && obj.Id == null) {
                // Need to add this user to this team so they can admin it
                obj.ClubId = '' + selectedClub;
                obj.ClubName = clubs.find((e) => e.Id === selectedClub).Name;

                obj.OwnerEmail = props.userAuth.email;
                obj.Owner = props.userAuth.UserDetails.ProfileName || props.userAuth.email;

                await fireModel.addTeam(obj, props.userAuth.UserDetails);

                setCompleteTeam(obj);
            }
            else
                await fireModel.putTeam(obj);

            fireModel.updateUserTeam(props.userAuth.UserDetails.Id, obj);

            setAlertMessage("Team Data Updated");
            setIsDirty(false);
        }
        else
            setAlertMessage("Nothing to update");

    }

    const title = addingTeam ? "New Team" : props.selectedTeam.Name;
    const owner = props.selectedTeam === undefined || completeTeam === undefined ? "Not registered - contact support to claim" : completeTeam.Owner;


    if ((props.selectedTeam === null || props.selectedTeam === undefined) && !addingTeam)
        return "Select a team";
    return (<div><Container fluid className="px-0">
        <Alert className="fade mb-0 alert-info" dismissible={(e) => { closeAlert() }} open={alertMessage !== ''} >
            <i className="fa fa-info mx-2"></i>{alertMessage}
        </Alert>
    </Container>
        <Container fluid className="main-content-container px-4">

            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">{title}</h6>
                </CardHeader>
                <ListGroup flush>
                    <ListGroupItem className="p-3">

                        <Row>
                            <Col>
                                <Form>
                                    <Row>
                                        <Col md="6" >
                                            <FormGroup>
                                                <label htmlFor="feTeamName">Team Name</label>
                                                <FormInput className="invalid"
                                                    required
                                                    type="text"
                                                    id="feEmailfeTeamName"
                                                    placeholder="Enter Team Name"
                                                    value={teamName}
                                                    onChange={handleTeamNameChange}
                                                    valid={teamName.trim().length > 5}
                                                    invalid={teamName.trim().length <= 5}
                                                />
                                                <FormFeedback invalid>Team name must be longer than 5 characters.</FormFeedback>
                                                <FormFeedback valid>Valid</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" >

                                            <FormGroup>
                                                <label htmlFor="feInputState">Club</label>
                                                <FormSelect id="feInputState"
                                                    defaultValue={addingTeam ? '' : props.selectedTeam.ClubId}
                                                    disabled={!addingTeam}
                                                    onChange={(choice) => {

                                                        setSelectedClub(choice.target.value);
                                                    }}
                                                    valid={selectedClub !== ''}
                                                    invalid={selectedClub === ''}
                                                >
                                                    <option value=''>Choose...</option>
                                                    {
                                                        clubs.map((e) => {
                                                            return <option value={e.Id} selected={!addingTeam && e.Id === props.selectedTeam.ClubId}>{e.Name}</option>;
                                                        })
                                                    }
                                                </FormSelect>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="10" >
                                            <label htmlFor="feOwnerName">Owner</label>
                                            <FormInput
                                                type="label"
                                                id="feOwnerName"
                                                value={owner}
                                                disabled="true"
                                            />
                                        </Col>

                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>


                            <Col className="text-center" md="11">
                                <Button theme={displayedPane === "1" ? "primary" : "white"} size="sm" className="mb-2 mr-1" onClick={(event) => { toggleView("1") }}>Gender</Button>
                                <Button theme={displayedPane === "2" ? "primary" : "white"} size="sm" className="mb-2 mr-1" onClick={(event) => { toggleView("2") }}>Age Group</Button>
                                <Button theme={displayedPane === "3" ? "primary" : "white"} size="sm" className="mb-2 mr-1" onClick={(event) => { toggleView("3") }}>Coaches</Button>
                                <Button outline size="sm" theme="primary" className="mb-2 mr-1" onClick={(event) => { saveData() }}>{addingTeam ? "Create" : "Update"}</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center" md="3" size="sm"> <label>Team Id: {props.selectedTeam ? props.selectedTeam.Id : ""}</label></Col>
                            <Col className="text-center" md="3" size="sm"> <label>uid: {props.userAuth.uid}</label></Col>
                            <Col className="text-center" md="3" size="sm"> <label>User.Id: {userDetails.Id}</label></Col>
                        </Row>
                    </ListGroupItem>
                </ListGroup >
            </Card>
            {displayedPane === "1" ?
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Team Gender(s)</h6>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                                <Col md="12" >
                                    <GenderButtonGroups initialGender={addingTeam ? null : newGender} setGender={setGender} groupVerbage={true} />
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card> : ""
            }

            {displayedPane === "2" ?
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Team Age Group(s)</h6>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                                <Col md="12" >
                                    <AgeGroups selectedTeam={completeTeam} setAgeGroups={setAgeGroups} isDirty={isDirty} setAgeEventList={setAgeEventList} />
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card> : ""}

            {displayedPane === "3" ?
                <Card small className="mb-4">
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">Management Team</h6>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="p-3">
                            <Row>
                                <Col md="12" >
                                    <TeamRoles selectedTeam={completeTeam} setRoles={setRoles} setStaffList={setStaffList} />
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card> : ""}
        </Container></div>);
}


)
export default TeamDetails;
