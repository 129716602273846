import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

import firebase from "firebase/app";

import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';

function reducer(state = { userAuth: null }, action) {
  switch (action.type) {
    case 'SIGNED_IN':
      return Object.assign({}, state, { userAuth: action.userAuth });
    case 'SIGNED_OUT':
      firebase.auth().signOut();
      localStorage.removeItem('dataKey');
      return Object.assign({}, state, { userAuth: null, selectedTeam: undefined });
    case 'TEAM_SET':
      return Object.assign({}, state, { selectedTeam: action.Team })
    case 'MEET_SET':
      return Object.assign({}, state, { selectedMeet: action.Meet })
    default:
      return state;
  }
}

let store = Redux.createStore(reducer)

export default () => (
  <ReactRedux.Provider store={store}>
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <div>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={withTracker(props => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              })}
            />
          );
        })}
      </div>
    </Router>
  </ReactRedux.Provider>
);
