import React from "react";
import { Container, Row, Col } from "shards-react";
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

const Profile = () => (
    <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
            <PageTitle title="Profile &amp; Team Management" subtitle="Information and help" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Next Meet</h4>

                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <strong>General</strong>
                            <p className="text-muted d-block mb-2">If you have more than one team you can use this screen to alternate between your various teams.  Maintaining the teams has the advantage of reports only including the athletes needed.</p>
                            <p className="text-muted d-block mb-2">Save time by preselecting the next event you are participating in.  This will be used as the default meet.</p>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <img
                            src={require("../images/content-management/nextmeet.png")}
                            alt="welcome"
                            width="100%"
                        />
                    </CardHeader>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Configuration</h4>
                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <strong>New Meet</strong>
                            <p className="text-muted d-block mb-2">You can add a meet to your team.  Note this meet will be available to all teams in your club, so check before creating a new meet as another coach could have already made the meet.</p>
                            <p className="text-muted d-block mb-2">You can select the Genders and the age groups included in the team.  These can be used as report filters</p>
                        </ListGroupItem>
                    </ListGroup>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <strong>Age group and Gender</strong>
                            <p className="text-muted d-block mb-2">You can select the Genders and the age groups included in the team.  These can be used as report filters</p>
                        </ListGroupItem>
                    </ListGroup>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <strong>Coach List</strong>
                            <p className="text-muted d-block mb-2">Invite another coach to the team or add a team parent.  This allows them to help sign athletes up to events.</p>
                        </ListGroupItem>
                    </ListGroup>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <strong>Add team</strong>
                            <p className="text-muted d-block mb-2">Add a complete new team.  Note you can navigate to the team list and add athletes one by one, or do a bulk import from this web site (coming soon!).</p>
                        </ListGroupItem>
                    </ListGroup>

                </Card>
            </Col>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <img
                            src={require("../images/content-management/profileinfo.png")}
                            alt="welcome"
                            width="100%"
                        />
                    </CardHeader>
                </Card>
            </Col>
        </Row>
    </Container >
);

export default Profile;
