import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button, CardHeader, CardBody, Badge } from "shards-react";
import { Link, useLocation, Redirect } from 'react-router-dom';
import PageTitle from "../components/common/PageTitle";
import { connect } from 'react-redux'
import FirestoreModel from '../firestoreModel'
import Roster from "../utils/Roster";
import Athlete from "../utils/athlete";
import AthleteTable from "../components/AthleteTable";
//import isDeepEqual from 'fast-deep-equal/react'

function mapStateToProps(state) {
    return ({
        userAuth: state.userAuth,
        selectedTeam: state.selectedTeam
    });
}

function mapDispatchToProps(dispatch) {

    return {
        onSetTeam: (Team) => {
            console.log({ team: Team });
            dispatch({ type: 'TEAM_SET', Team });
        },
        onSignIn: (userAuth) => {
            dispatch({ type: 'SIGNED_IN', userAuth });
        },
        onSignOut: (userAuth) => {
            dispatch({ type: 'SIGNED_OUT', userAuth });
        }

    };
}

const ManageTeam = connect(mapStateToProps, mapDispatchToProps)(function (props) {
    let [athleteList, setAthleteList] = useState({});
    let [textAreaContents, setTextAreaContents] = useState({});
    let [rosterUpdates, setRosterUpdates] = useState({});
    let [previewData, setPreviewData] = useState({});
    let [refreshNeeded, setRefreshNeeded] = useState({ needed: true });

    let runExport = false;
    if (!(props.location === undefined || props.location === null)) {
        if (!(props.location.state === undefined || props.location.state === null))
            runExport = props.location.state === "export";
    }

    const fireModel = new FirestoreModel();

    //const teamRef = useRef(athleteList);

    useEffect(() => {
        if (props.selectedTeam === undefined || props.selectedTeam === null) {
            return;
        }

        Promise.all(
            [

                fireModel.fetchPrivateTeamList(props.selectedTeam.Id)

            ]
        ).then((values) => {
            setAthleteList(values[0]);
            setRefreshNeeded({ needed: false })
            if (runExport) {
                showExportData(values[0]);
            }
            else
                setTextAreaContents('');

        })

    }, [props.selectedTeam]);

    const teamPicked = !(props.selectedTeam === null || props.selectedTeam === undefined || refreshNeeded.needed === true)



    let showExportData = (athleteList) => {

        let textOutput = "";

        athleteList.forEach((ath) => {
            let athlete = new Athlete();
            athlete.CopyFromObject(ath);
            if (textOutput.length > 0)
                textOutput += "\n";
            textOutput += athlete.CreateExportLine();
        })
        setTextAreaContents(textOutput);
    }

    let handleChange = (event) => {
        setTextAreaContents(event.target.value);
        setPreviewData({});
        setRosterUpdates({});
    }

    const textAreaStyle = {
        whiteSpace: 'pre',
        overflowWrap: 'normal',
        overflowX: 'scroll',
        width: '100%'
    };

    let showProposed = (action) => {
        switch (action) {
            case 0:
                setPreviewData({ Title: "Added", Data: rosterUpdates.proposedAdded });
                break;
            case 1:
                setPreviewData({ Title: "Removed", Data: rosterUpdates.proposedRemoved });
                break;
            case 2:
                setPreviewData({ Title: "Updates", Data: rosterUpdates.proposedChanged });
                break;
            case 3:
                setPreviewData({ Title: "Untouched", Data: rosterUpdates.proposedUnchanged });
                break;
            default:
                setPreviewData({ Title: "Added", Data: rosterUpdates.proposedAdded });
                break;
        }
    }

    let previewUpdates = (action) => {
        console.log(action)
        let roster = new Roster();
        roster.originalTeam = athleteList;

        let arrayOfLines = textAreaContents.split('\n');
        const loadedRoster = [];
        let errorString = ""

        for (let n = 0; n < arrayOfLines.length; n++) {
            var ath = new Athlete();
            errorString = ath.PopulateFromLine(arrayOfLines[n]);
            if (errorString !== "") {
                alert("Please check line " + (n + 1) + "\n" + errorString);
                break;
            }
            loadedRoster.push(ath);
        }

        if (errorString === "") {
            let response = roster.validateRosterChanges(loadedRoster, action);
            if (response === "")
                setRosterUpdates(roster)
            else {
                setRosterUpdates(null)
                alert(response);
            }

        }
        // Dump preview data to force a redraw
    }

    let updateAthletes = () => {
        rosterUpdates.proposedChanged.forEach((item) => {
            fireModel.putAthlete(item, props.selectedTeam.Id);
        })

        rosterUpdates.proposedAdded.forEach((item) => {
            fireModel.putAthlete(item, props.selectedTeam.Id);
        })

        rosterUpdates.proposedRemoved.forEach((item) => {
            fireModel.delAthlete(item, props.selectedTeam.Id);
        })

        Promise.all([
            fireModel.putAthleteCount(props.selectedTeam.Id)
        ]).then(() => {
            alert("Completed!  Please return to team page to review the changes.")
        })



        setRosterUpdates({});
        setPreviewData({});
        setAthleteList({});
        setRefreshNeeded({ needed: true })
        //props.onSetTeam(null);
    }


    const rosterVerified = !(rosterUpdates.originalTeam === undefined || rosterUpdates.originalTeam === null);

    return (

        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title="Import or Export Roster" subtitle="Roster" md="12" className="ml-sm-auto mr-sm-auto" />
            </Row>
            <Row>
                <Col lg="6">
                    <Card small className="mb-4 pt-3">
                        <CardHeader className="border-bottom text-left">
                            My Roster
                        </CardHeader>
                        <CardBody>
                            <div className="mb-3 mx-auto">
                                <details>
                                    <summary>Paste team here.  Click for more details....</summary>
                                    <p className="text-sm">
                                        Paste your team data here.<br />
                                        Format: items in [] are optional<br />Name, Gender, Age group,[aka],[dob],[allergies],[parent name],[parennt cell],[Conference Id]<br />
                                        Comma OR tab separators are valid.  Examples:<br />
                                        Angela Jones,F,8-10,Angie<br />
                                        Andrew Jones,M,8-10,Andy
                                    </p>
                                </details>
                                <div>
                                    <label htmlFor='teamText'>Roster text: </label>
                                </div>
                                <div>
                                    <textarea rows="20" value={textAreaContents} style={textAreaStyle} name="teamText" placeholder="Paste your roster here....." onChange={handleChange} ></textarea>
                                </div>
                                <div className="mb-3 text-right">
                                    {teamPicked ? <Button className="mb-2 mr-1" type="button" theme="white" onClick={() => { previewUpdates(1) }}>Preview - Replace Team</Button> : <Link to={"TeamDashboard"}>Select a team</Link>}
                                    {teamPicked ? <Button className="mb-2 mr-1" type="button" theme="white" onClick={() => { previewUpdates(0) }}>Preview - Merge with Team</Button> : ""}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="6">
                    <Card small className="mb-4 pt-3">
                        <CardHeader className="border-bottom text-left">
                            Preview of Updates to Team
                        </CardHeader>
                        {
                            rosterVerified === false ? <CardBody><p>Verify the roster to see the change preview....</p></CardBody> :
                                <CardBody>
                                    <Button className="mb-2 mr-1" type="button" theme="white" onClick={() => { showProposed(0) }}>Added<Badge className="badge badge-pill badge-danger">{rosterUpdates.proposedAdded.length === 0 ? "" : rosterUpdates.proposedAdded.length}</Badge>   </Button>
                                    <Button className="mb-2 mr-1" type="button" theme="white" onClick={() => { showProposed(1) }}>Removed<Badge className="badge badge-pill badge-danger">{rosterUpdates.proposedRemoved.length === 0 ? "" : rosterUpdates.proposedRemoved.length}</Badge>   </Button>
                                    <Button className="mb-2 mr-1" type="button" theme="white" onClick={() => { showProposed(2) }}>Merged<Badge className="badge badge-pill badge-danger">{rosterUpdates.proposedChanged.length === 0 ? "" : rosterUpdates.proposedChanged.length}</Badge>   </Button>
                                    <Button className="mb-2 mr-1" type="button" theme="white" onClick={() => { showProposed(3) }}>Untouched<Badge className="badge badge-pill badge-danger">{rosterUpdates.proposedUnchanged.length === 0 ? "" : rosterUpdates.proposedUnchanged.length}</Badge>   </Button>
                                    <Button className="mb-2 mr-1" type="button" theme="info" onClick={() => { updateAthletes() }}>Update</Button>
                                    <hr />
                                    <p>{previewData.Title}</p>
                                    <AthleteTable athleteList={previewData.Data} smallSet={true} />
                                </CardBody>
                        }
                    </Card>
                </Col>
            </Row>
        </Container >

    )
});

export default ManageTeam;
