import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, BlankLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import TeamDashboard from "./views/TeamDashboard";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import MainPage from "./views/MainPage";
import PrivacyPolicy from "./views/privacy";
import EventView from './views/eventview';
import GridView from './views/gridview';
import TableView from './views/tableview';
import Support from './views/support'
import Welcome from './views/welcome'
import AthleteList from './views/athletelist'
import CoachReports from './views/coachreports'
import ManageTeam from './views/manageteam'
import Profile from './views/profile'
import SignUpView from "./views/signupview";
import Download from "./views/download";
import Tutorial from "./views/tutorial";
import TeamDetails from "./components/TeamDetails";
import AthleteAdmin from "./components/AthleteAdmin";
import MeetDetails from "./components/MeetDetails";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/mainpage" />
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/TeamDashboard",
    layout: DefaultLayout,
    component: TeamDashboard
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/mainpage",
    layout: DefaultLayout,
    component: MainPage
  },
  {
    path: "/eventview",
    layout: BlankLayout,
    component: EventView
  },
  {
    path: "/gridview",
    layout: BlankLayout,
    component: GridView
  },
  {
    path: "/tableview",
    layout: BlankLayout,
    component: TableView
  },
  {
    path: "/privacy",
    layout: DefaultLayout,
    component: PrivacyPolicy
  },
  {
    path: "/support",
    layout: DefaultLayout,
    component: Support
  },
  {
    path: "/athletelist",
    layout: DefaultLayout,
    component: AthleteList
  },
  {
    path: "/manageteam",
    layout: DefaultLayout,
    component: ManageTeam
  },
  {
    path: "/coachreports",
    layout: DefaultLayout,
    component: CoachReports
  },
  {
    path: "/profile",
    layout: DefaultLayout,
    component: Profile
  },
  {
    path: "/signupview",
    layout: DefaultLayout,
    component: SignUpView
  },
  {
    path: "/welcome",
    layout: DefaultLayout,
    component: Welcome
  },
  {
    path: "/teamdetails",
    layout: DefaultLayout,
    component: TeamDetails
  },
  {
    path: "/athleteadmin",
    layout: DefaultLayout,
    component: AthleteAdmin
  },
  {
    path: "/download",
    layout: DefaultLayout,
    component: Download
  },
  {
    path: "/tutorial",
    layout: DefaultLayout,
    component: Tutorial
  },
  {
    path: "/meetdetails",
    layout: DefaultLayout,
    component: MeetDetails
  }



];
