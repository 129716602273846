/* eslint jsx-a11y/anchor-is-valid: 0 */
import { Link } from 'react-router-dom';
import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // First list of posts.
      PostsListOne: [
        {
          backgroundImage: require("../images/content-management/welcome.png"),
          category: "Guide",
          categoryTheme: "dark",
          author: "David Hodgson",
          authorAvatar: require("../images/avatars/1.jpg"),
          title: "Welcome",
          url: "/welcome",
          body:
            "Welcome to the Youth Track Manager app, reduce the overhead of meet preparation...",
          date: "1st January 2021"
        },
        {
          backgroundImage: require("../images/content-management/athletelist.png"),
          category: "Guide",
          categoryTheme: "dark",
          author: "David Hodgson",
          authorAvatar: require("../images/avatars/1.jpg"),
          title: "Athlete List",
          url: "/athletelist",

          body:
            "Manage your athlete sign-ups and show personal and season records...",
          date: "1st January 2021"
        },
        {
          backgroundImage: require("../images/content-management/coachreport.png"),
          category: "Guide",
          categoryTheme: "dark",
          author: "David Hodgson",
          authorAvatar: require("../images/avatars/1.jpg"),
          title: "Coach Reports",
          url: "/coachreports",

          body:
            "View the athlete sign-ups in grid format or in event format with personal or season records...",
          date: "1st January 2021"
        },
        {
          backgroundImage: require("../images/content-management/profileinfo.png"),
          category: "Guide",
          categoryTheme: "dark",
          author: "David Hodgson",
          authorAvatar: require("../images/avatars/1.jpg"),
          title: "Team Management and Profile",
          url: "/profile",

          body:
            "Make changes to your team, their events, coaching team and age groups...",
          date: "1st January 2021"
        }
      ],

      // Second list of posts.
      PostsListTwo: [
        {
          backgroundImage: require("../images/content-management/5.jpeg"),
          category: "Privacy Policy",
          categoryTheme: "info",
          author: "Anna Ken",
          authorAvatar: require("../images/avatars/1.jpg"),
          url: "/privacy",
          title:
            "Privacy Policy",
          body:
            "Privacy of your data is important to us.  We do not, and will not, collect or share any of your information...",
          date: "1st January 2021"
        },
        {
          backgroundImage: require("../images/content-management/6.jpeg"),
          category: "Support",
          categoryTheme: "info",
          author: "David Hodgson",
          authorAvatar: require("../images/avatars/1.jpg"),
          url: "/support",
          title:
            "Support & Contact Us",
          body:
            "Your feedback is important to us, you are always welcome to connect with us for your support or suggestions...",
          date: "1st January 2021"
        }
      ]

    };
  }

  render() {
    const {
      PostsListOne,
      PostsListTwo
    } = this.state;

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Welcome to Youth Track Manager" subtitle="Information Pages" className="text-sm-left" />
        </Row>

        {/* First Row of Posts */}
        <Row>
          {PostsListOne.map((post, idx) => (
            <Col lg="3" md="6" sm="12" className="mb-4" key={idx}>
              <Card small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url(${post.backgroundImage})`, backgroundPosition: "top" }}
                >
                  <Badge
                    pill
                    className={`card-post__category bg-${post.categoryTheme}`}
                  >
                    {post.category}
                  </Badge>
                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{ backgroundImage: `url('${post.authorAvatar}')` }}
                    >
                      Written by {post.author}
                    </a>
                  </div>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <Link to={post.url} className="text-fiord-blue">{post.title}</Link>
                  </h5>
                  <p className="card-text d-inline-block mb-3">{post.body}</p>
                  <span className="text-muted">{post.date}</span>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Second Row of Posts */}
        <Row>
          {PostsListTwo.map((post, idx) => (
            <Col lg="6" sm="12" className="mb-4" key={idx}>
              <Card small className="card-post card-post--aside card-post--1">
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url('${post.backgroundImage}')` }}
                >
                  <Badge
                    pill
                    className={`card-post__category bg-${post.categoryTheme}`}
                  >
                    {post.category}
                  </Badge>
                  <div className="card-post__author d-flex">
                    <a
                      href="#"
                      className="card-post__author-avatar card-post__author-avatar--small"
                      style={{ backgroundImage: `url('${post.authorAvatar}')` }}
                    >
                    </a>
                  </div>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <Link to={post.url} className="text-fiord-blue">{post.title}</Link>
                  </h5>
                  <p className="card-text d-inline-block mb-3">{post.body}</p>
                  <span className="text-muted">{post.date}</span>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default MainPage;
