import FirestoreModel from '../firestoreModel'
import React, { useState, useEffect } from "react";
import RecordHelper from '../utils/recordhelper';

import { FormInput } from "shards-react";


const AthleteSignUpComponent = (function (props) {
    let [eventMap, setEventMap] = useState({});

    useEffect(() => {
        let newEventMap = [];
        if (props.teamEvents.length !== undefined) {
            newEventMap = props.teamEvents.filter((e) => e.DivisionId == props.athlete.AgeGroupId);
            newEventMap.forEach((event) => {
                event.athleteSignUp = props.signups.filter((e) => e.AthleteId == props.athlete.AthleteId && e.EventId == event.EventId);

                if (props.athlete.Records != null)
                    event.Record = props.athlete.Records.filter((e) => e.Event.EventId == event.EventId);

                if (event.athleteSignUp.length > 0) {
                    event.athleteSignUp[0].in = (event.athleteSignUp[0].SignedUp === 1 || event.athleteSignUp[0].SignedUp === true);
                }
            })

            newEventMap.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1)

            setEventMap(newEventMap);
        }


    }
        , [props.athlete]
    )



    let toggleParticipating = (e, event, participating) => {

        //athleteSignUp.in = !athleteSignUp.in;

        let newEventMap = [];

        newEventMap = eventMap.filter((e) => e.EventId !== event.EventId);

        if (participating) {
            if (event.athleteSignUp.length === 0) {
                const athleteSignUp = {
                    "AthleteId": props.athlete.AthleteId,
                    "Name": props.athlete.Name,
                    "EventId": event.EventId,
                    "Record": "",
                    "RecordType": "",
                    "Notes": "",
                    "in": true
                };
                event.athleteSignUp.push(athleteSignUp);
            }
        }
        event.athleteSignUp[0].in = participating;

        newEventMap.push(event);

        newEventMap.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1)

        // Save the sign-ups for the event as it changed
        // this will record the teams sign-ups for this one event.
        const fireModel = new FirestoreModel();

        Promise.all(
            [
                fireModel.putMeetTeamSignupsAsync(props.team.Id, props.meet.MeetId, event.EventId, props.athlete, event.athleteSignUp[0])
            ]
        ).then((values) => {
            props.onChangesDetected(newEventMap);
        })

        setEventMap(newEventMap);
    }

    let notesUpdates = (sender, signUp, save) => {

        signUp.athleteSignUp[0].Notes = sender.target.value;
        toggleParticipating(null, signUp, true)
    }

    if (props.athlete == null || props.athlete.AthleteId === undefined || props.meet == null || eventMap.length === undefined || eventMap.length === 0)
        return "";
    else {
        // Make display easier, create an array with everything needed to show
        return (
            <table className="table mb-0" >
                <thead className="bg-info">
                    <tr>
                        <th scope="col" className="border-0">Name</th>
                        <th scope="col" className="border-0">Signed Up</th>
                        <th scope="col" className="border-0" colSpan="2">Record</th>
                        <th scope="col" className="border-0">Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        eventMap.map((teamEvent) => {
                            let helper = new RecordHelper();
                            let recordExists = false;
                            if (teamEvent.Record != null && teamEvent.Record.length > 0) {
                                helper = new RecordHelper(teamEvent.Record[0]);
                                recordExists = true;
                            }

                            const participating = !(teamEvent.athleteSignUp.length === 0 || teamEvent.athleteSignUp[0].in === false);


                            return (<tr key={teamEvent.EventId}>
                                <td>
                                    {teamEvent.Name}
                                </td>
                                <td>
                                    {participating === false ?
                                        <img
                                            id="main-logo"
                                            className="d-inline-block align-top mr-1"
                                            style={{ maxWidth: "25px" }}
                                            src={require("../images/checkdisabled.png")}
                                            alt="Not signed up"
                                            onClick={(event) => { toggleParticipating(event, teamEvent, true) }}
                                        />
                                        :
                                        <img
                                            id="main-logo"
                                            className="d-inline-block align-top mr-1"
                                            style={{ maxWidth: "25px" }}
                                            src={require("../images/checkenabled.png")}
                                            alt="Not signed up"
                                            onClick={(event) => { toggleParticipating(event, teamEvent, false) }}
                                        />
                                    }
                                </td>
                                <td>
                                    {recordExists ? helper.getResultString() : ""}
                                </td>
                                <td>
                                    {recordExists ? helper.RecordType : ""}
                                </td>
                                <td>
                                    {participating ? <FormInput
                                        type="text"
                                        id="feOwnerName"
                                        placeholder="Notes"
                                        onChange={(e) => notesUpdates(e, teamEvent, false)}
                                        value={teamEvent.athleteSignUp[0].Notes} /> : ""}

                                </td>
                            </tr>)

                        }
                        )
                    }

                </tbody>
            </table>
        )
    }
}
)

export default AthleteSignUpComponent;