import React from "react";
import PropTypes from "prop-types";

const BlankLayout = ({ children, noNavbar, noFooter }) => (
    <div>{children}</div>

);

BlankLayout.propTypes = {
    /**
     * Whether to display the navbar, or not.
     */
    noNavbar: PropTypes.bool,
    /**
     * Whether to display the footer, or not.
     */
    noFooter: PropTypes.bool
};

BlankLayout.defaultProps = {
    noNavbar: false,
    noFooter: false
};

export default BlankLayout;
