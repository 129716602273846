import React, { useState, useEffect, Fragment } from "react";
import FirestoreModel from '../../firestoreModel'
import { Link } from 'react-router-dom';
import { ButtonGroup, Button } from "shards-react";
import AgeGroup from "../../utils/agegroup";
import TeamAgeEvents from "./TeamAgeEvents";

const AgeGroups = function (props) {
    let [ageGroupList, setAgeGroupList] = useState([]);
    let [ageGroupIdOpen, setAgeGroupIdOpen] = useState(null);

    useEffect(() => {
        const fireModel = new FirestoreModel();

        Promise.all(
            [
                fireModel.fetchAllAgeGroups()
            ]
        ).then((values) => {

            // add an attribute to each object to indicate if it is selected or not, based on team
            if (props.selectedTeam !== undefined && props.selectedTeam.Divisions !== undefined) {

                props.selectedTeam.Divisions.forEach((d) => {
                    let x = values[0].find((db) => { return db.id == d.AgeGroupId })
                    x.selected = true;
                })
            }

            setAgeGroupList(values[0]);
        })



    }, [props.selectedTeam]);

    let showEvents = (event) => {
        if (props.isDirty && ageGroupIdOpen === null) {
            alert("Please save your updates before editing the team's events.")
            return;
        }


        if (event === null)
            setAgeGroupIdOpen(null);
        else
            setAgeGroupIdOpen(event.id);
    }


    let switchDisplay = (event) => {
        //let x = ageGroupList.find((all) => { return all.id === event })
        //x.selected = true;


        let updatedList = ageGroupList.map(item => {
            if (item.id === event) {
                if (item.selected === undefined)
                    item.selected = false;
                //return { ...item, selected: !item.selected }; //gets everything that was already in item, and updates "done"
                let ageGroup = new AgeGroup();
                ageGroup.SetFromObj(item);
                ageGroup.selected = !item.selected;

                return ageGroup;
            }
            return item; // else return unmodified item 
        });



        let x = updatedList.filter((all) => { return all.selected === true })
        props.setAgeGroups(x);

        setAgeGroupList(updatedList);

    };


    if (ageGroupList === undefined || ageGroupList === null || ageGroupList.Length === 0)
        return (<div>Loading...</div>);
    else
        return (
            <div>
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <td>Age Range</td>
                            <td>Gender</td>
                            <td>Nickname</td>
                            <td>In team?</td>
                            <td>Events</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ageGroupList.map((ageGroup) => {
                                return (<Fragment>
                                    <tr key={ageGroup.id} className={ageGroupIdOpen === ageGroup.id ? "bg-info" : "bg-light"} >
                                        <td>{ageGroup.AgeRange}</td>
                                        <td>{ageGroup.Gender.GenderFormattted()}</td>
                                        <td>{ageGroup.Description}</td>
                                        <td>
                                            <img
                                                id="main-logo"
                                                className="d-inline-block align-top mr-1"
                                                style={{ maxWidth: "25px" }}

                                                src={ageGroup.selected === undefined ?
                                                    require("../../images/crossdisabled.png") :
                                                    ageGroup.selected === true ? require("../../images/checkenabled.png") : require("../../images/crossdisabled.png")
                                                }
                                                alt="Age group in use"
                                                onClick={(e) => switchDisplay(ageGroup.id)}
                                            />
                                        </td>
                                        <td>{ageGroup.selected !== undefined ? <Button className="mb-2 mr-1 text-left" type="button" theme="white" onClick={(e) => showEvents(ageGroupIdOpen === ageGroup.id ? null : ageGroup)}>{ageGroupIdOpen === ageGroup.id ? "Done" : "..."}</Button> : ""}</td>
                                    </tr>
                                    {ageGroupIdOpen === ageGroup.id ? <tr><td colSpan={4}><TeamAgeEvents selectedTeam={props.selectedTeam} selectedAgeGroupId={ageGroup.id} setAgeEventList={props.setAgeEventList} /></td></tr> : ""}
                                </Fragment>
                                )

                            })
                        }
                    </tbody>
                </table>

            </div>
        );

}

export default AgeGroups;