import React from "react";
import { Container, Row, Col } from "shards-react";
import {
    Card,
    CardHeader
} from "shards-react";


import PageTitle from "../components/common/PageTitle";

const Support = () => (
    <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
            <PageTitle title="Support" subtitle="Contact Us" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
            <Col lg="8">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Contact Us</h4>
                            <p className="text-muted d-block mb-2">Other coaches and team parents are a great source of information, ask around. However we are always here to help just send an email to me using this <a href="mailto:drhodgson@gmail.com">email address.</a></p>
                        </div>
                    </CardHeader>
                </Card>
            </Col>
            <Col lg="4">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Request New Club</h4>
                            <span className="text-muted d-block mb-2">New clubs can easily be added.  We need to know a few things such as club name, image, location etc please email me for details.</span>
                        </div>
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">General Help</h4>
                            <span className="text-muted d-block mb-2">See the information pages here for more information.</span>
                        </div>
                    </CardHeader>
                </Card>
            </Col>
        </Row>
    </Container>
);

export default Support;
