import React from "react";
import { Container, Row, Col } from "shards-react";
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

const Download = () => (
    <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
            <PageTitle title="Download" subtitle="Download the app for Apple or Android" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Download for Apple</h4>

                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <img
                                id="main-logo"
                                className="d-inline-block align-top mr-1"
                                style={{ maxWidth: "50px" }}
                                src={require("../images/checkenabled.png")}
                                alt="Not signed up"
                                align="left"
                            />
                            <p className="text-muted d-block mb-2">To install the app on your Apple device please visit the AppStore and search for Youth Track Manager.  Or click the link below to be taken there.</p>
                            <br />
                            <img
                                id="main-logo"
                                className="d-inline-block align-top mr-1"
                                style={{ maxWidth: "50px" }}
                                src={require("../images/appleicon.png")}
                                alt="Not signed up"
                                align="left"
                            />
                            <a href="https://apps.apple.com/us/app/youth-track-manager/id1493085294">Youth Track Manager on Apple AppStore</a>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Download for Android</h4>
                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <img
                                id="main-logo"
                                className="d-inline-block align-top mr-1"
                                style={{ maxWidth: "50px" }}
                                src={require("../images/checkenabled.png")}
                                alt="Not signed up"
                                align="left"
                            />
                            <p className="text-muted d-block mb-2">To install the app on your Android device please visit the Google PlayStore and search for Youth Track Team Manager.  Or click the link below to be taken there.</p>
                            <img
                                id="main-logo"
                                className="d-inline-block align-top mr-1"
                                style={{ maxWidth: "50px" }}
                                src={require("../images/androidicon.png")}
                                alt="Not signed up"
                                align="left"
                            />
                            <a href="https://play.google.com/store/apps/details?id=com.companyname.TrackManager">Youth Track Manager on Google PlayStore</a>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        </Row>
    </Container >
);

export default Download;