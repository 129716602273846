import React from "react";
import { Container, Row, Col } from "shards-react";
import {
    Card,
    CardHeader,
    Button,
    ListGroup,
    ListGroupItem,
    Progress
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

const CoachReports = () => (
    <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
            <PageTitle title="Coach Reports" subtitle="Information and help" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
            <Col lg="5">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Event View Report</h4>

                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <strong>Event View</strong>
                            <p className="text-muted d-block mb-2">This report shows the events in chronlogical order along with the athletes signed up.   Where applicable the athletes are shown in the order of their personal or season records.</p>
                            <p className="text-muted d-block mb-2">If available the current qualifying time will be displayed in the event's box.</p>
                            <p className="text-muted d-block mb-2">The report can be shared with the team, printed for a hard copy or used online.</p>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="7">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <img
                            src={require("../images/content-management/eventview.png")}
                            alt="welcome"
                            width="100%"
                        />
                    </CardHeader>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col lg="5">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Classic Grid View Report</h4>
                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <strong>Classic Grid</strong>
                            <p className="text-muted d-block mb-2">This report shows the classic grid.   You can optionally show or hide those athletes that are out for the day.</p>
                            <p className="text-muted d-block mb-2">The report can be shared with the team, printed for a hard copy or used online.</p>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="7">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <img
                            src={require("../images/content-management/coachreport.png")}
                            alt="welcome"
                            width="100%"
                        />
                    </CardHeader>
                </Card>
            </Col>
        </Row>
    </Container >
);

export default CoachReports;
