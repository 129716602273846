import React, { useState, useEffect, useRef, Component } from "react";
import '../assets/grid.css';
import FirestoreModel from '../firestoreModel'
import PageTitle from '../components/pagetitle.js'
import DisplayFilters from '../components/displayfilters.js'
import AthleteEventSignUp from "../utils/athletesignup";

import '../assets/reportfilters.css'

const TableViewContext = React.createContext(null)

const DisplayOptions = function (props) {
  let [formOpen, setFormOpen] = useState(false);

  const [displayOptions, setDisplayOptions] = React.useContext(TableViewContext).displayOptionsStore

  let switchDisplay = () => {
    if (formOpen === true)
      props.displaychange(displayOptions);
    setFormOpen(!formOpen);
  }

  let handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setDisplayOptions({ ...displayOptions, [event.target.name]: value });
  }


  if (displayOptions.printerFriendly === true)
    return null;

  if (formOpen === true)
    return (
      <div className="form-popup" id="myForm">
        <form className="form-container">
          <label><b>Options</b></label>
          <hr />

          <label htmlFor="paticipatingEventsOnly">Participating events</label>
          <input type="checkbox" name="paticipatingEventsOnly" checked={displayOptions.paticipatingEventsOnly} onChange={handleChange} />
          <br></br>

          {props.showingTimes === false ?
            <React.Fragment >
              <label htmlFor="ahtleteOut">Include absent</label>
              <input type="checkbox" name="athleteOut" checked={displayOptions.athleteOut} onChange={handleChange} />
              <br></br>

              <label htmlFor="showConferenceId">Conference Id</label>
              <input type="checkbox" name="showConferenceId" checked={displayOptions.showConferenceId} onChange={handleChange} />
              <br></br>

              <label htmlFor="ahtleteTotals">Athlete totals</label>
              <input type="checkbox" name="athleteTotals" checked={displayOptions.athleteTotals} onChange={handleChange} />
              <br></br>
            </React.Fragment> :
            <React.Fragment >
              <label htmlFor="showQualifiers">Show Qualifiers</label>
              <input type="checkbox" name="showQualifiers" checked={displayOptions.showQualifiers} onChange={handleChange} />
              <br></br>
            </React.Fragment>
          }

          <label htmlFor="printerFriendly">Printer Friendly</label>
          <input type="checkbox" name="printerFriendly" checked={displayOptions.printerFriendly} onChange={handleChange} />
          <br></br>

          <label htmlFor="switchNames">Switch Names</label>
          <input type="checkbox" name="switchNames" checked={displayOptions.switchNames} onChange={handleChange} />
          <br></br>

          <label htmlFor="conferenceOrder">Conf Id Order</label>
          <input type="checkbox" name="conferenceOrder" checked={displayOptions.conferenceOrder} onChange={handleChange} />
          <br></br>

          <label htmlFor="showPRs">Show PR</label>
          <input type="checkbox" name="showPRs" checked={displayOptions.showPRs} onChange={handleChange} />
          <br></br>

          <label htmlFor="showComments">Show Comments</label>
          <input type="checkbox" name="showComments" checked={displayOptions.showComments} onChange={handleChange} />
          <br></br>

          <button type="button" className="btn cancel" onClick={switchDisplay}>Apply</button>
        </form>
      </div>
    );
  else
    return (
      <div className="form-popup" id="myForm">
        <form className="form-container">
          <button type="button" className="btn cancel" onClick={switchDisplay}>Display Options</button>
        </form>
      </div>
    )
}



function ItemData(props) {
  let count = 0;

  const [displayOptions, setDisplayOptions] = React.useContext(TableViewContext).displayOptionsStore



  let fullName = props.athlete.AlsoKnownAs || props.athlete.Name;

  if (displayOptions.switchNames === true) {
    let n = fullName.indexOf(",");
    if (n > 0)
      fullName = fullName.substring(n + 1) + " " + fullName.substring(0, n - 1)
  }

  return (
    <tr>
      <td>{fullName}</td>
      {
        displayOptions.showConferenceId === true ? <td>{props.athlete.ConferenceId}</td> : null
      }
      {
        props.events.map((event) => {
          let x = props.signUps.filter((signup) => signup.EventId == event.EventId);

          let tag = ''
          if (x.length > 0) {
            count++;
            tag = 'X'

            console.log(x)

            if (displayOptions.showPRs) {
              if (props.athlete.Records !== undefined) {
                const item = props.athlete.Records.filter((record) => record.Event.EventId == event.EventId);
                if (item !== undefined) {
                  if (item.length > 0) {

                    tag = item[0].Result;
                  }
                }

              }

            }

            if (displayOptions.showComments) {

              const athleteEventSignUp = new AthleteEventSignUp();
              athleteEventSignUp.fromObject(x[0]);

              if (athleteEventSignUp.Notes !== '')
                tag = athleteEventSignUp.Notes;

            }

            return <td className="eventCell" style={{ backgroundColor: "lightgray" }} key={event.EventId}>{tag}</td>
          }
          return <td className="eventCell" key={event.EventId}>{tag}</td>
        })
      }
      {displayOptions.athleteTotals ? <td>{count}</td> : ""}

    </tr>
  )
}

function ItemDataOut(props) {
  let count = 0;

  const [displayOptions, setDisplayOptions] = React.useContext(TableViewContext).displayOptionsStore

  console.log({ outAthlete: props.athlete })

  return (
    <tr>
      <td>{props.athlete.AlsoKnownAs === null || props.athlete.AlsoKnownAs === "" ? props.athlete.Name : props.athlete.AlsoKnownAs}</td>
      {
        props.options.showConferenceId === true ? <td>{props.athlete.ConferenceId}</td> : null
      }

      {
        props.events.map((event) => {
          let tag = 'OUT'
          return <td className="eventCell" key={event.EventId}>{tag}</td>
        })
      }
      {displayOptions.athleteTotals ? <td>{count}</td> : ""}
    </tr>
  )
}

function GridHeader(props) {
  const [displayOptions, setDisplayOptions] = React.useContext(TableViewContext).displayOptionsStore

  return (
    <tr>
      <td>Name</td>
      {
        props.options.showConferenceId === true ? <td>Id</td> : null
      }

      {
        props.data.map((event) => {
          return <td className="eventCell" key={event.EventId}>{event.Name}</td>
        })
      }
      {displayOptions.athleteTotals ? <td>Total</td> : ""}
    </tr>
  )
}

function GridFooter(props) {
  let grandTotal = 0

  const [displayOptions, setDisplayOptions] = React.useContext(TableViewContext).displayOptionsStore

  return (
    <tr>
      <td>Totals</td>
      {
        props.options.showConferenceId === true ? <td></td> : null
      }

      {
        props.data.map((event) => {
          grandTotal += event.numberAthletes;
          return <td className="eventCell" key={event.EventId}>{event.numberAthletes}</td>
        })
      }
      {displayOptions.athleteTotals ? <td>{grandTotal}</td> : ""}
    </tr>

  )
}



const TableView = function (props) {

  let [displayOptions, setDisplayOptions] = useState(
    {
      athleteOut: false,
      paticipatingEventsOnly: false,
      showConferenceId: false,
      athleteTotals: true,
      printerFriendly: false,
      switchNames: false,
      conferenceOrder: false,
      showPRs: false,
      showComments: false
    }
  )



  const store = {
    displayOptionsStore: [displayOptions, setDisplayOptions]
  }

  return <TableViewContext.Provider value={store}><TableGridView {...props} /></TableViewContext.Provider>
}



const TableGridView = function (props) {
  const pageData = useRef({ isFetching: true });
  let [isFetching, setIsFetching] = useState(true);
  let [team, setTeam] = useState();

  const [displayOptions, setDisplayOptions] = React.useContext(TableViewContext).displayOptionsStore

  useEffect(() => {

    const fireModel = new FirestoreModel();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    pageData.current.events = [];

    let teamId = ""
    pageData.current.meetId = ""


    if (!(props.location === undefined || props.location === null))
      if (!(props.location.state === undefined || props.location.state === null)) {
        teamId = props.location.state.teamId;
        pageData.current.meetId = props.location.state.meetId;
      }

    if (params.get('Team') !== null)
      teamId = params.get('Team');

    if (params.get('Meet') !== null)
      pageData.current.meetId = params.get('Meet');


    Promise.all(
      [
        fireModel.fetchMeetTeamTagsAsync(teamId, pageData.current.meetId),
        fireModel.fetchMeetTeamEventSignupsAsync(teamId, pageData.current.meetId),
        fireModel.fetchPublicTeamList(teamId),
        fireModel.fetchTeamAsync(teamId),
        fireModel.fetchMeetAsync(pageData.current.meetId)
      ]
    ).then((values) => {
      pageData.current.NotSignedUp = values[0].NotSignedUp;
      pageData.current.OutList = values[0].OutList;
      pageData.current.AthleteList = values[2];
      pageData.current.AthleteSignUps = values[1].athleteSignUps;

      //setNotSignedUp(values[0].NotSignedUp);
      //setOutList(values[0].OutList);
      //setAthleteList(values[2]);
      //setAthleteSignUps(values[1].athleteSignUps);

      let team = values[3];
      pageData.current.meet = values[4];

      // This can be optimized, only read defaul list if there are missing ageEvents
      // however this should be default usage really
      Promise.all([
        fireModel.fetchDefaultTeamEvents(team)
      ]
      ).then((values) => {
        const eventsPossibleDupe = values[0];

        // get the default event list only for  those age groups that dont have specified AgeEvents
        eventsPossibleDupe.forEach((item) => {
          if (team.AgeEvents.find((e) => e.DivisionId === item.DivisionId) === undefined) {
            if (pageData.current.events.filter((e) => e.EventId === item.EventId).length === 0) {
              pageData.current.events.push(item);
            }

          }
        })

        console.log(pageData.current.events)

        // Now add events from the teams age groups
        team.AgeEvents.forEach((item) => {
          if (pageData.current.events.filter((e) => e.EventId === item.EventId).length === 0) {
            pageData.current.events.push(item);
          }
        }
        )

        pageData.current.events.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1)



        team.Divisions.map((e) => { e.checked = true; return e; })

        // Create a new attribute in events summing the number of athletes
        pageData.current.events.forEach(function (event) {
          var numberAthlete = 0;
          for (let n = 0; n < pageData.current.AthleteSignUps.length; n++) {
            if (pageData.current.AthleteSignUps[n].EventId == event.EventId)
              numberAthlete++;
          }
          event.numberAthletes = numberAthlete;
        })
        setIsFetching(false);
        setTeam(team);

      }
      )
      //let queryData = { notSignedUp, outList, athleteList, athleteSignUps, events, teamId: this.teamId, meetId: this.meetId, meet };
      //this.setState({ objData: queryDate, isFetching: false, team: team });
    }
    )


    console.log("useEffect");

  }
    , [props.location]
  )


  let displayChange = (arg) => {
    if (arg.athleteOut !== undefined)
      setDisplayOptions(arg);
    else {
      const newTeam = team;
      newTeam.Divisions = arg.ageGroups;
      setTeam({ ...newTeam });
    }

  }

  if (isFetching === true || team === undefined)
    return <div>Loading Please Wait....</div>
  else {
    const showOuts = displayOptions.athleteOut;

    if (displayOptions.conferenceOrder) {
      pageData.current.AthleteList = pageData.current.AthleteList.sort((a, b) => {

        let fa = a.ConferenceId == null || a.ConferenceId === "" || parseInt(a.ConferenceId) === null ? 999999 : parseInt(a.ConferenceId);
        let fb = b.ConferenceId == null || b.ConferenceId === "" || parseInt(b.ConferenceId) === null ? 999999 : parseInt(b.ConferenceId);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;

      })
    }
    else {
      pageData.current.AthleteList = pageData.current.AthleteList.sort((a, b) => {
        let fa = a.AlsoKnownAs === null || a.AlsoKnownAs === '' ? a.Name.toLowerCase() : a.AlsoKnownAs.toLowerCase(),
          fb = b.AlsoKnownAs === null || b.AlsoKnownAs === '' ? b.Name.toLowerCase() : b.AlsoKnownAs.toLowerCase();
        if (displayOptions.switchNames) {
          let n = fa.indexOf(",");
          if (n > 0)
            fa = fa.substring(n + 1) + " " + fa.substring(0, n - 1);

          n = fb.indexOf(",");
          if (n > 0)
            fb = fb.substring(n + 1) + " " + fb.substring(0, n - 1);

        }
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;

      })

    }



    let filteredAthletes = pageData.current.AthleteList.filter((athlete) => {
      if (team.Divisions.filter((ageGroup) => ageGroup.checked === true && ageGroup.AgeGroupId === athlete.AgeGroupId).length > 0) {
        if (pageData.current.OutList !== undefined && pageData.current.OutList.includes(athlete.AthleteId) === true && showOuts === false)
          return null;
        else
          return athlete;
      }
      else {
        return null;
      }

    });

    console.log({ filteredList: filteredAthletes });

    let filteredEvents = pageData.current.events;
    let localAthleteSignUps = pageData.current.AthleteSignUps;

    filteredEvents.forEach(function (event) {
      var numberAthlete = 0;
      for (let n = 0; n < localAthleteSignUps.length; n++) {
        if (localAthleteSignUps[n].EventId == event.EventId && localAthleteSignUps[n].SignedUp == true) {
          if (filteredAthletes.filter((e) => { return e.AthleteId === localAthleteSignUps[n].AthleteId ? e : null; }).length > 0)
            numberAthlete++;
        }

      }
      event.numberAthletes = numberAthlete;
    }
    );

    if (displayOptions.paticipatingEventsOnly === true) {
      filteredEvents = filteredEvents.filter((e) => { return e.numberAthletes > 0 ? e : null; });
    }


    return (
      <div className="GridView">
        <PageTitle team={team} meet={pageData.current.meet} />
        <DisplayOptions displaychange={displayChange} showingTimes={false} />
        <DisplayFilters displaychange={displayChange} currentDisplayOptions={displayOptions} divisions={team.Divisions} printerFriendly={displayOptions.printerFriendly} />
        <table>
          <thead >
            <GridHeader data={filteredEvents} options={displayOptions} />
          </thead>
          <tbody>
            {

              filteredAthletes.map((e) => {
                // Is this athlete in the selected age groups

                let athEvents = pageData.current.AthleteSignUps.filter(item => item.AthleteId === e.AthleteId && item.SignedUp == true);
                if (pageData.current.OutList !== undefined && pageData.current.OutList.includes(e.AthleteId) === true) {
                  if (showOuts)
                    return (
                      <ItemDataOut athlete={e} key={e.AthleteId} events={filteredEvents} options={displayOptions} />
                    )
                  else
                    return null;

                }
                else {
                  return (
                    <ItemData athlete={e} key={e.AthleteId} signUps={athEvents} events={filteredEvents} options={displayOptions} />
                  )

                }
              }
              )

            }
          </tbody>
          <tfoot>
            <GridFooter data={filteredEvents} options={displayOptions} />
          </tfoot>
        </table>
      </div>
    )
  }
}

export default TableView;