import React, { useState, useEffect, useRef, Fragment } from "react";
import FirestoreModel from '../../firestoreModel'
import { Link } from 'react-router-dom';
import { ButtonGroup, Button } from "shards-react";
import AgeGroup from "../../utils/agegroup";
import { Col, FormCheckbox } from "shards-react";


const TeamAgeEvents = function (props) {
    let [allEventChoices, setAllEventChoices] = useState([]);
    let [allEvents, setAllEvents] = useState([]);
    let [defaultEvents, setDefaultEvents] = useState([]);
    const mounted = useRef({ defaultMode: false, changedData: false });
    let [selectedTeamAgeEvents, setSelectedTeamAgeEvents] = useState(props.selectedTeam.AgeEvents || []);
    let obj = selectedTeamAgeEvents !== undefined ? selectedTeamAgeEvents.find((e) => e.DivisionId === props.selectedAgeGroupId) : undefined;
    const isDefaultEvents = obj === undefined;
    let [isInDefaultMode, setIsInDefaultMode] = useState(isDefaultEvents);

    console.log({ isDefaultEvents })

    useEffect(() => {
        const fireModel = new FirestoreModel();

        console.log('Promise started')
        Promise.all(
            [
                fireModel.fetchAllEventsAsync(),
                fireModel.fetchDefaultTeamEvents(props.selectedTeam)
            ]
        ).then((values) => {
            // set the local copy
            console.log('Promise completed')

            /*if (isDefaultEvents) {
                selectedEvents = selectedAgeEvents.filter((e) => { return e.DivisionId === props.selectedAgeGroupId });
                selectedEvents = allEventChoices;
            }*/

            mergeAgeGroupWithAll(values[0], values[1], true);

            setAllEvents(values[0]);
            setDefaultEvents(values[1]);
        })

    }, []);


    // DEFAULT CHANGING
    useEffect(() => {
        console.log('Is in defaultMode change')
        if (mounted.current.defaultMode) {
            // whenever default mode changes
            console.log('Mounted Current')
            console.log({ isInDefaultMode })

            // Make a new array, excluding any from this division, ie blank for this division
            const newSet = selectedTeamAgeEvents.filter((e) => e.DivisionId !== props.selectedAgeGroupId)
            console.log({ newSet })

            if (isInDefaultMode) {
                defaultEvents.filter((e) => { return e.DivisionId === props.selectedAgeGroupId }).forEach((e) => {
                    e.selected = true;
                    newSet.push(e)
                });
                console.log({ newSet })
                //setSelectedTeamAgeEvents(newSet);
                //mergeAgeGroupWithAll(allEvents, defaultEvents);
            }
            //setSelectedTeamAgeEvents(newSet);
            mergeAgeGroupWithAll(allEvents, defaultEvents);
            //props.setAgeEventList(null);
        }
        else {
            console.log('NOT Mounted Current')
            mounted.current.defaultMode = true;
        }
        console.log('Is LEAVING defaultMode change')

    }, [isInDefaultMode]);

    useEffect(() => {
        console.log('Is in allEventChoices change')
        if (mounted.current.changedData) {
            // whenever default mode changes
            // get the current data, combined with existing data and send it back to parent
            console.log('SENDING allEventChoices to PARENT')

            // Send all data not in this division
            const newSet = selectedTeamAgeEvents.filter((e) => e.DivisionId !== props.selectedAgeGroupId)
            if (!isInDefaultMode) {
                allEventChoices.forEach((e) => {
                    if (e.selected) {
                        let newObj = { ...e };
                        delete newObj["selected"];
                        newObj.DivisionId = props.selectedAgeGroupId;
                        newSet.push(newObj);
                    }

                })
            }
            // plus if not default, send this data
            props.setAgeEventList(newSet);
        }
        else {
            mounted.current.changedData = true;
        }

        console.log('Is LEAVING allEventChoices change')

    }, [allEventChoices]);


    let mergeAgeGroupWithAll = (completeEventList, defaultEvents, initialLoad) => {
        console.log('In mergeAgeGroupWithAll')
        let selectedEvents = [];

        // NOT SURE ABOUT THIS
        if (isInDefaultMode) {
            // Though we in the default more which means no data, we need to show data on screen
            selectedEvents = defaultEvents.filter((e) => { return e.DivisionId === props.selectedAgeGroupId });
        }
        else {
            if (initialLoad) {
                // Get starting data from the team itself, after this do not replace with this base data
                selectedEvents = selectedTeamAgeEvents.filter((e) => { return e.DivisionId === props.selectedAgeGroupId });
                //selectedEvents = allEventChoices;
            }
        }

        const newEventChoices = completeEventList.filter((e) => { return true })

        selectedEvents.forEach((selectedEvent) => {
            const eventItem = newEventChoices.find((e) => e.EventId === selectedEvent.EventId);
            eventItem.selected = true;
            eventItem.SortOrder = selectedEvent.SortOrder;
        }
        )

        newEventChoices.sort((a, b) => {
            const newASO = a.selected ? a.SortOrder : 100 + a.SortOrder;
            const newBSO = b.selected ? b.SortOrder : 100 + b.SortOrder;

            return newASO > newBSO ? 1 : -1

            //if (a.selected === b.selected)
            //    return a.SortOrder > b.SortOrder ? 1 : -1
            //else
            //    return a.selected ? 0 : 1 > b.selected ? 0 : 1 ? 1 : -1;
        }
        )

        setAllEventChoices(newEventChoices);
    }

    let switchInclusion = (evt) => {
        let updatedList = allEventChoices.map(item => {
            if (item.EventId === evt.EventId) {
                if (item.selected === undefined)
                    item.selected = false;
                //return { ...item, selected: !item.selected }; //gets everything that was already in item, and updates "done"
                item.selected = !item.selected;

                return item;
            }
            return item; // else return unmodified item 
        });

        mergeAgeGroupWithAll(allEvents, defaultEvents);

        //setAllEventChoices(updatedList)
    }

    let goDefault = (e) => {
        console.log('Go Default')
        setIsInDefaultMode(e.target.checked);
        console.log(e)
    }


    let MoveUp = (evt) => {
        evt.SortOrder++;
        mergeAgeGroupWithAll(allEvents, defaultEvents);
        console.log({ moveUp: evt })
    }

    let MoveDown = (evt) => {
        evt.SortOrder--;
        mergeAgeGroupWithAll(allEvents, defaultEvents);
        console.log({ moveDown: evt })
    }

    if (allEventChoices === undefined || defaultEvents === null || allEventChoices.Length === 0)
        return (<div>Loading...</div>);
    else
        return (
            <table className="table mb-0">
                <thead>
                    <tr>
                        <td><FormCheckbox checked={isInDefaultMode} toggle large onClick={(e) => goDefault(e)}>Click here to use the default set of events, this is the most common configuration.</FormCheckbox></td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>Long Name</td>
                        <td>Sort Order</td>
                        {isInDefaultMode ? "" : <React.Fragment>
                            <td>Include</td>
                            <td>Up</td>
                            <td>Down</td>
                        </React.Fragment>}
                    </tr>
                </thead>
                <tbody>
                    {
                        allEventChoices.map((evt) => {
                            return (
                                <tr key={evt.EventId} className="bg-light" >
                                    <td>{evt.Name}</td>
                                    <td>{evt.LongName}</td>
                                    {isInDefaultMode && !evt.selected ? "" : <td>{evt.SortOrder}</td>}
                                    {isInDefaultMode ? "" : <React.Fragment>
                                        <td>
                                            <img
                                                id="main-logo"
                                                className="d-inline-block align-top mr-1"
                                                style={{ maxWidth: "25px" }}

                                                src={evt.selected !== true ?
                                                    require("../../images/crossdisabled.png") :
                                                    evt.selected === true ? require("../../images/checkenabled.png") : require("../../images/crossdisabled.png")
                                                }
                                                alt="Age group in use"
                                                onClick={(e) => switchInclusion(evt)}
                                            />
                                        </td>
                                        <td><img
                                            id="main-logo"
                                            className="d-inline-block align-top mr-1"
                                            style={{ maxWidth: "25px" }}

                                            src={evt.selected !== true ?
                                                require("../../images/crossdisabled.png") :
                                                evt.selected === true ? require("../../images/moveup.png") : require("../../images/moveup.png")
                                            }
                                            alt="Age group in use"
                                            onClick={(e) => { MoveDown(evt) }}
                                        /></td>
                                        <td><img
                                            id="main-logo"
                                            className="d-inline-block align-top mr-1"
                                            style={{ maxWidth: "25px" }}

                                            src={evt.selected !== true ?
                                                require("../../images/crossdisabled.png") :
                                                evt.selected === true ? require("../../images/movedown.png") : require("../../images/movedown.png")
                                            }
                                            alt="Age group in use"
                                            onClick={(e) => { MoveUp(evt) }}
                                        /></td></React.Fragment>
                                    }
                                </tr>
                            )

                        })
                    }
                </tbody>
            </table>
        );

}

export default TeamAgeEvents;