import React, { useState, useEffect } from "react";
import FirestoreModel from '../../firestoreModel'
import { Link } from 'react-router-dom';
import { ButtonGroup, Button } from "shards-react";
import OptionalTextCollector from "../common/OptionalTextCollector";
import { connect } from 'react-redux'


function mapStateToProps(state) {
    console.log({ state: state });
    return ({
        userAuth: state.userAuth,
    });
}

function mapDispatchToProps(dispatch) {

    return {
        onSignIn: (userAuth) => {
            dispatch({ type: 'SIGNED_IN', userAuth });
        },
        onSignOut: (userAuth) => {
            dispatch({ type: 'SIGNED_OUT', userAuth });
        }
    };
}


const TeamRoles = connect(mapStateToProps, mapDispatchToProps)(function (props) {
    let [coachList, setCoachList] = useState([]);
    let [initialTeam, setInitialTeam] = useState(props.selectedTeam);

    useEffect(() => {
        setCoachList(setCoachListFromRoleAndStaff(props.selectedTeam.Roles, props.selectedTeam.Staff))

    }, [props.selectedTeam]);

    let setCoachListFromRoleAndStaff = (roles, staff) => {
        let staffInfo = [];

        if (roles !== undefined) {
            Object.entries(roles).forEach((role) => {
                let newRole = {
                    id: role[0],
                    roleName: role[1],
                    profileName: 'Unknown user',
                    eMail: '',
                    status: ''

                }

                if (staff !== undefined) {
                    // for this role find the staff entry by id or by email

                    let obj = staff.find(x => x.Id === newRole.id);

                    if (obj === undefined)
                        obj = staff.find(x => x.EMail === newRole.id);

                    if (obj !== undefined) {
                        newRole.profileName = obj.ProfileName;
                        newRole.eMail = obj.EMail;
                        newRole.status = obj.Status;
                    }
                }
                // it is possible that there are orphanes invites... allow user to remove these


                // if this role is the current user, get from current user
                if (newRole.id == props.userAuth.UserDetails.UserId) {
                    newRole.eMail = props.userAuth.UserDetails.Email;
                    newRole.profileName = props.userAuth.UserDetails.ProfileName;
                    newRole.roleName = props.userAuth.UserDetails.Role;
                }

                staffInfo.push(newRole);
            }
            )
        }

        if (staff !== undefined) {
            const list = staff.map((e) => {
                if (e.Status === 'Declined')
                    return {
                        profileName: e.ProfileName,
                        eMail: e.EMail,
                        status: e.Status
                    }
                return null;
            }
            )
            list.forEach((e) => {
                if (e !== null)
                    staffInfo.push(e);
            }
            )
        }

        return staffInfo;
    }

    let switchDisplay = (event) => {
    };

    let removeClicked = async (role) => {
        if (window.confirm("Are you sure you wish to remove:\n\n" + (role.profileName || role.eMail) + "\n\nClick OK to confirm")) {


            let newRoles = initialTeam.Roles;
            // it is possible to delete a pending request, this would be found by email address
            if (newRoles[role.eMail] === undefined)
                delete newRoles[role.id];
            else
                delete newRoles[role.eMail];



            let newStaffList = coachList.map((e) => {
                if (!(e.EMail === role.eMail || e.id === role.id))
                    return {
                        EMail: e.eMail,
                        Id: e.id,
                        Status: e.status,
                        ProfileName: e.profileName
                    };
                return null;

            }).filter((e) => e !== null);

            props.setRoles(newRoles);
            props.setStaffList(newStaffList);

            setCoachList(setCoachListFromRoleAndStaff(newRoles, newStaffList))
        }
    }

    let onSendInvite = async (event) => {
        // If user is already added abort
        let userFound = coachList.find((e) => { return e.id === event || e.eMail === event })

        if (userFound !== undefined) {
            alert('User is already registered on the coaching staff.  Please check the email address.')
            return;
        }

        // Check if the email address exists
        const fireModel = new FirestoreModel();
        userFound = await fireModel.getUserByEmail(event);

        // User has added an invite
        // Save to the completeTeam
        let newRoles = initialTeam.Roles;
        let newStaffList = coachList.map((obj) => {
            return {
                EMail: obj.eMail === undefined ? '' : obj.eMail,
                Id: '' + obj.id === undefined ? '' : obj.id,
                Status: obj.status,
                ProfileName: obj.profileName
            }
        })

        // Add a new Role entry
        //if (userFound !== null)
        //    newRoles[userFound.UserId] = 'Coach'
        //else
        // Always add with email address
        newRoles[event] = 'Coach'

        // Also add a new Staff entry
        const newStaffMember = {
            EMail: event,
            Id: userFound !== null ? userFound.UserId : '',
            Status: 'Pending',
            ProfileName: userFound !== null ? userFound.ProfileName : '',
        }

        newStaffList.push(newStaffMember);

        props.setRoles(newRoles);
        props.setStaffList(newStaffList);

        setCoachList(setCoachListFromRoleAndStaff(newRoles, newStaffList))

    }

    if (coachList === undefined || coachList === null || coachList.Length === 0)
        return (<div>Loading...</div>);
    else
        return (
            <table className="table mb-0">
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>Email</td>
                        <td>Role</td>
                        <td>Status</td>
                        <td>Remove</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        coachList.map((role) => {
                            return (
                                <tr key={role.id} className="bg-light" >
                                    <td>{role.profileName}</td>
                                    <td>{role.eMail}</td>
                                    <td>{role.roleName}</td>
                                    <td>{role.status}</td>
                                    <td><Link to={{ pathname: "teamdetails", state: "join" }}><Button className="mb-2 mr-1 text-left" type="button" theme="white" onClick={(e) => { removeClicked(role) }}>...</Button></Link></td>
                                </tr>
                            )

                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={5} className="text-center">
                            <OptionalTextCollector defaultValue="" startLabel="Invite" typeOfData="text" confirmLabel="Confirm" cancelLabel="Cancel" dataUpdate={(e) => onSendInvite(e)} />
                        </td>
                    </tr>
                </tfoot>
            </table>

        );

})

export default TeamRoles;