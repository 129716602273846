import React from 'react'

function PageTitle(props) {
    return (
        <div>
            <h1>{props.team.Name}</h1>
            <h4>{props.meet.Name}</h4>
        </div>
    )
}

export default PageTitle;