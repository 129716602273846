import React from "react";
import { Container, Row, Col } from "shards-react";
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

const AthleteList = () => (
    <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
            <PageTitle title="Athlete List" subtitle="Information and help" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Athlete List Page</h4>

                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <strong>Athlete List</strong>
                            <p className="text-muted d-block mb-2">This is the list of athletes on your team. This screen has several modes depending on the activity. Activity is defined by the selections at the top of the page.</p>
                        </ListGroupItem>
                        <ListGroupItem className="px-4">
                            <strong>Sign up activity</strong>
                            <p className="text-muted d-block mb-2">Select the meet only. Select individual athletes to set their participation.  Swipe the slider next to the athlete name to mark the athlete as OUT..</p>
                        </ListGroupItem>
                        <ListGroupItem className="px-4">
                            <strong>Athlete performance activity</strong>
                            <p className="text-muted d-block mb-2">With just the event selected the screen will show your athletes in performance order. If SR not established PR will be used if any.</p>
                        </ListGroupItem>
                        <ListGroupItem className="px-4">
                            <strong>Athlete signed up for event activity</strong>
                            <p className="text-muted d-block mb-2">Select the meet and the event to see a list of athletes participating along with their times.</p>
                        </ListGroupItem>
                        <ListGroupItem className="px-4">
                            <strong>Missing / not yet signed up list</strong>
                            <p className="text-muted d-block mb-2">Select the meet and select event of not signed up to see the list of remaining athletes.</p>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <img
                            src={require("../images/content-management/athletelist.png")}
                            alt="welcome"
                            width="100%"
                        />
                    </CardHeader>
                </Card>
            </Col>
        </Row>
    </Container >
);

export default AthleteList;
