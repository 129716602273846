import React, { Component } from 'react'
import '../assets/reportfilters.css'

class DisplayOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formOpen: false,
            athleteOut: props.currentDisplayOptions.athleteOut,
            paticipatingEventsOnly: props.currentDisplayOptions.paticipatingEventsOnly,
            showConferenceId: props.currentDisplayOptions.showConferenceId,
            athleteTotals: props.currentDisplayOptions.showConferenceId,
            printerFriendly: props.currentDisplayOptions.printerFriendly,
            showQualifiers: props.currentDisplayOptions.showQualifiers
        };
        this.switchDisplay = this.switchDisplay.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.actionitem = this.props.displaychange;
        this.showingTimes = this.props.showingTimes;
    }

    switchDisplay() {
        if (this.state.formOpen === true)
            this.actionitem({
                athleteOut: this.state.athleteOut,
                athleteTotals: this.state.athleteTotals,
                paticipatingEventsOnly: this.state.paticipatingEventsOnly,
                showConferenceId: this.state.showConferenceId,
                printerFriendly: this.state.printerFriendly,
                showQualifiers: this.state.showQualifiers
            });
        this.setState({ formOpen: !this.state.formOpen });
    }

    handleChange(event) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({ [event.target.name]: value });
    }

    render() {
        console.log({ renderingDisplayOptions: this.state, times: this.showingTimes })
        if (this.props.printerFriendly === true)
            return null;

        if (this.state.formOpen === true)
            return (
                <div className="form-popup" id="myForm">
                    <form className="form-container">
                        <label><b>Options</b></label>
                        <hr />

                        <label htmlFor="paticipatingEventsOnly">Participating events</label>
                        <input type="checkbox" name="paticipatingEventsOnly" checked={this.state.paticipatingEventsOnly} onChange={this.handleChange} />
                        <br></br>

                        {this.showingTimes === false ?
                            <React.Fragment >
                                <label htmlFor="ahtleteOut">Include absent</label>
                                <input type="checkbox" name="athleteOut" checked={this.state.athleteOut} onChange={this.handleChange} />
                                <br></br>

                                <label htmlFor="showConferenceId">Conference Id</label>
                                <input type="checkbox" name="showConferenceId" checked={this.state.showConferenceId} onChange={this.handleChange} />
                                <br></br>

                                <label htmlFor="ahtleteTotals">Athlete totals</label>
                                <input type="checkbox" name="athleteTotals" checked={this.state.athleteTotals} onChange={this.handleChange} />
                                <br></br>
                            </React.Fragment> :
                            <React.Fragment >
                                <label htmlFor="showQualifiers">Show Qualifiers</label>
                                <input type="checkbox" name="showQualifiers" checked={this.state.showQualifiers} onChange={this.handleChange} />
                                <br></br>
                            </React.Fragment>
                        }

                        <label htmlFor="printerFriendly">Printer Friendly</label>
                        <input type="checkbox" name="printerFriendly" checked={this.state.printerFriendly} onChange={this.handleChange} />
                        <br></br>




                        <button type="button" className="btn cancel" onClick={this.switchDisplay}>Apply</button>
                    </form>
                </div>
            );
        else
            return (
                <div className="form-popup" id="myForm">
                    <form className="form-container">
                        <button type="button" className="btn cancel" onClick={this.switchDisplay}>Display Options</button>
                    </form>
                </div>
            )
    }
}

export default DisplayOptions;