import React from "react";
import Athlete from "../utils/athlete";


const AthleteTable = (function (props) {

    if (props.athleteList === null || props.athleteList === undefined)
        return "";
    else {
        return (
            <table className="table mb-0" >
                <thead className="bg-light">
                    {props.smallSet !== undefined && props.smallSet === true ?
                        <tr>
                            <th scope="col" className="border-0">Name</th>
                            <th scope="col" className="border-0">AlsoKnownAs</th>
                            <th scope="col" className="border-0">Gender</th>
                        </tr>
                        :
                        <tr>
                            <th scope="col" className="border-0">Name</th>
                            <th scope="col" className="border-0">AlsoKnownAs</th>
                            <th scope="col" className="border-0">Dob</th>
                            <th scope="col" className="border-0">Gender</th>
                            <th scope="col" className="border-0">Allergies</th>
                            <th scope="col" className="border-0">Conference Id</th>
                            <th scope="col" className="border-0">Parent Name</th>
                            <th scope="col" className="border-0">Parent Cell</th>
                        </tr>
                    }
                </thead>
                <tbody>
                    {
                        props.athleteList.map((athleteObject) => {
                            const athlete = new Athlete();
                            athlete.CopyFromObject(athleteObject);

                            if (props.smallSet !== undefined && props.smallSet === true)

                                return (
                                    <tr key={athlete.Name + athlete.AthleteId} onClick={(event) => { }}>
                                        <td>{athlete.Name}</td>
                                        <td>{athlete.AlsoKnownAs}</td>
                                        <td>{athlete.GenderFormattted()}</td>
                                    </tr>
                                )
                            else
                                return (
                                    <tr key={athlete.Name + athlete.AthleteId} onClick={(event) => { }}>
                                        <td>{athlete.Name}</td>
                                        <td>{athlete.AlsoKnownAs}</td>
                                        <td>{athlete.DoBFormattted()}</td>
                                        <td>{athlete.GenderFormattted()}</td>
                                        <td>{athlete.Allergies}</td>
                                        <td>{athlete.ConferenceId}</td>
                                        <td>{athlete.ParentName}</td>
                                        <td>{athlete.ParentCell}</td>
                                    </tr>
                                )
                        }
                        )
                    }
                </tbody>
            </table>
        )
    }
}
)

export default AthleteTable;