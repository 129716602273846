import Gender from "./gender.js"

class Athlete {
    constructor() {
        this.id = "";
        this.AthleteId = "";
        this.Name = "";
        this.Gender = new Gender();
        this.AgeGroupId = "0";
        this.AlsoKnownAs = "";
        this.Dob = "";
        this.Allergies = "";
        this.ParentName = "";
        this.ParentCell = "";
        this.ConferenceId = "";
    }

    CopyFromObject(sourceAthlete) {
        this.id = sourceAthlete.id;
        this.AthleteId = sourceAthlete.AthleteId;
        this.AgeGroupId = sourceAthlete.AgeGroupId;
        this.Name = sourceAthlete.Name;
        this.Gender.SetFromCode(sourceAthlete.Gender);
        this.AlsoKnownAs = sourceAthlete.AlsoKnownAs;
        this.Dob = sourceAthlete.Dob;
        this.Allergies = sourceAthlete.Allergies;
        this.ParentName = sourceAthlete.ParentName;
        this.ParentCell = sourceAthlete.ParentCell;;
        this.ConferenceId = sourceAthlete.ConferenceId;
    }

    rawObject(teamId) {
        let obj = {
            AthleteId: this.AthleteId,
            AgeGroupId: this.AgeGroupId,
            Name: this.Name,
            Gender: this.Gender.id,
            AlsoKnownAs: this.AlsoKnownAs,
            Dob: this.Dob,
            Allergies: this.Allergies,
            ParentName: this.ParentName,
            ParentCell: this.ParentCell,
            ConferenceId: this.ConferenceId,
            TeamId: teamId
        };
        return obj;
    }

    GenderFormattted() {
        return (this.Gender === "0" || this.Gender === "M" || this.Gender === 0 ? 'M' : 'F');
    }

    GenderRaw() {
        return (this.Gender === "0" || this.Gender === "M" || this.Gender === 0 ? 0 : 1);
    }

    DoBFormattted() {
        return (this.Dob === "0001-01-01" ? "" : this.Dob);
    }

    CreateExportLine() {
        const delimeterUsed = ',';
        let outputString = this.Name + delimeterUsed;
        outputString += this.Gender.genderCode() + delimeterUsed;

        switch (this.AgeGroupId) {
            case "1":
            case "2":
                outputString += "GREMLINS";
                break;
            case "3":
            case "4":
                outputString += "BANTAMS";
                break;
            case "5":
            case "6":
                outputString += "MIDS";
                break;
            case "7":
            case "8":
                outputString += "YOUTH";
                break;
            case "9":
            case "10":
                outputString += "INT";
                break;
            default:
                outputString += "YOUNG";
        }
        outputString += delimeterUsed;

        outputString += this.AlsoKnownAs === null ? delimeterUsed : this.AlsoKnownAs + delimeterUsed;
        outputString += this.Dob === null ? delimeterUsed : this.Dob + delimeterUsed;
        outputString += this.Allergies === null ? delimeterUsed : this.Allergies + delimeterUsed;
        outputString += this.ParentName === null ? delimeterUsed : this.ParentName + delimeterUsed;
        outputString += this.ParentCell === null ? delimeterUsed : this.ParentCell + delimeterUsed;
        outputString += this.safeGetString(this.ConferenceId);

        return outputString
    }

    PopulateFromLine(line) {
        var newLine = line.replace(/\t/gi, ",");
        var contents = newLine.split(',');

        // Gender is required
        if (contents[1] === null || contents[1] === '' || contents[1] === undefined)
            return 'Gender is required';
        contents[1] = contents[1].toUpperCase();

        if (contents[0] === null || contents[0] === '' || contents[0] === undefined)
            return 'Name is required';

        if (contents[2] === null || contents[2] === '' || contents[2] === undefined)
            return 'Age Group is required';

        contents[2] = contents[2].toUpperCase();

        switch (contents[1]) {
            case 'M':
            case "MALE":
            case "0":
                this.Gender.id = 0;
                break;
            case 'F':
            case "FEMALE":
            case "1":
                this.Gender.id = 1;
                break;
            default:
                return 'Gender group must be M / F';
        }

        switch (contents[2]) {
            case "G":
            case "GREMLIN":
            case "GREMLINS":
            case "7-8":
                if (this.Gender.id == "0")
                    this.AgeGroupId = "1";
                else
                    this.AgeGroupId = "2";
                break;
            case "B":
            case "BANTAM":
            case "BANTAMS":
            case "9-10":
                if (this.Gender.id == "0")
                    this.AgeGroupId = "3";
                else
                    this.AgeGroupId = "4";
                break;
            case "M":
            case "MID":
            case "MIDS":
            case "11-12":
                if (this.Gender.id == "0")
                    this.AgeGroupId = "5";
                else
                    this.AgeGroupId = "6";
                break;
            case "Y":
            case "YOUTH":
            case "YOUTHS":
            case "13-14":
                if (this.Gender.id == "0")
                    this.AgeGroupId = "7";
                else
                    this.AgeGroupId = "8";
                break;
            case "I":
            case "INT":
            case "INTERMEDIATE":
            case "15-16":
            case "15+":
                if (this.Gender.id == "0")
                    this.AgeGroupId = "9";
                else
                    this.AgeGroupId = "10";
                break;
            case "YOUNG":
            case "17-18":
            case "16+":
                if (this.Gender.id == "0")
                    this.AgeGroupId = "nA5BGHND4ymr2Y1NLjpg";
                else
                    this.AgeGroupId = "HPp1ETHpisGsEAm5t9aH";
                break;
            default:
                return 'Age group must GREMLINS / BANTAMS / MIDS / YOUTH / INT / YOUNG';
        }

        try {
            // Age group and gender set above and convered into type codes.
            this.Name = this.safeGetString(contents[0]);
            this.AlsoKnownAs = this.safeGetString(contents[3]);
            this.Dob = this.safeGetString(contents[4], "0001-01-01");
            this.Allergies = this.safeGetString(contents[5]);
            this.ParentName = this.safeGetString(contents[6]);
            this.ParentCell = this.safeGetString(contents[7]);
            this.ConferenceId = this.safeGetString(contents[8]);
        }
        catch (e) {
            console.log(e)
        }

        return '';
    }

    safeGetString(item, defaultText = "") {
        if (item === undefined || item == null)
            return defaultText;
        return item === "" ? defaultText : item;
    }
};


export default Athlete;
