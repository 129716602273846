import React, { Component } from 'react'
import '../assets/grid.css';
import FirestoreModel from '../firestoreModel'
import PageTitle from '../components/pagetitle.js'
import DisplayOptions from '../components/displayoptions.js'
import DisplayFilters from '../components/displayfilters.js'

function ItemData(props) {
  let count = 0;

  const highlight = true;

  return (
    <tr>
      <td>{props.athlete.AlsoKnownAs || props.athlete.Name}</td>
      {
        props.options.showConferenceId === true ? <td>{props.athlete.ConferenceId}</td> : null
      }
      {
        props.events.map((event) => {
          let x = props.signUps.filter((signup) => signup.EventId == event.EventId);
          let tag = ''
          if (x.length > 0) {
            count++;
            tag = 'X'
            return <td className="eventCell" style={{ backgroundColor: "lightgray" }} key={event.EventId}>{tag}</td>
          }
          return <td className="eventCell" key={event.EventId}>{tag}</td>
        })
      }
      <td>{count}</td>
    </tr>
  )
}

function ItemDataOut(props) {
  let count = 0;

  console.log({ outAthlete: props.athlete })

  return (
    <tr>
      <td>{props.athlete.AlsoKnownAs === null || props.athlete.AlsoKnownAs === "" ? props.athlete.Name : props.athlete.AlsoKnownAs}</td>
      {
        props.options.showConferenceId === true ? <td>{props.athlete.ConferenceId}</td> : null
      }

      {
        props.events.map((event) => {
          let tag = 'OUT'
          return <td className="eventCell" key={event.EventId}>{tag}</td>
        })
      }
      <td>{count}</td>
    </tr>
  )
}

function GridHeader(props) {
  return (
    <tr>
      <td>Name</td>
      {
        props.options.showConferenceId === true ? <td>Id</td> : null
      }

      {
        props.data.map((event) => {
          return <td className="eventCell" key={event.EventId}>{event.Name}</td>
        })
      }
      <td>Total</td>
    </tr>
  )
}

function GridFooter(props) {
  let grandTotal = 0
  return (
    <tr>
      <td>Totals</td>
      {
        props.options.showConferenceId === true ? <td></td> : null
      }

      {
        props.data.map((event) => {
          grandTotal += event.numberAthletes;
          return <td className="eventCell" key={event.EventId}>{event.numberAthletes}</td>
        })
      }
      <td>{grandTotal}</td>
    </tr>

  )
}


class GridView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      displayOptions: {
        athleteOut: false,
        paticipatingEventsOnly: false,
        showConferenceId: false,
        athleteTotals: true,
        printerFriendly: false
      },
      objData: []
    };
    this.teamId = ''
    this.meetId = ''

    this.displayChange = this.displayChange.bind(this);

    if (!(props.location === undefined || props.location === null))
      if (!(props.location.state === undefined || props.location.state === null)) {
        this.teamId = props.location.state.teamId;
        this.meetId = props.location.state.meetId;
      }


  }




  displayChange(arg) {
    if (arg.athleteOut !== undefined)
      this.setState({ displayOptions: arg });
    else {
      const team = this.state.team;
      team.Divisions = arg.ageGroups;
      this.setState({ team: team });
    }

  }

  render() {



    if (this.state.isFetching === true)
      return <div>Loading Please Wait....</div>
    else {
      const showOuts = this.state.displayOptions.athleteOut;

      console.log({ filteredList: this.state.objData.athleteList });

      let filteredAthletes = this.state.objData.athleteList.filter((athlete) => {
        if (this.state.team.Divisions.filter((ageGroup) => ageGroup.checked === true && ageGroup.AgeGroupId === athlete.AgeGroupId).length > 0) {
          if (this.state.objData.outList !== undefined && this.state.objData.outList.includes(athlete.AthleteId) === true && showOuts === false)
            return null;
          else
            return athlete;
        }
        else {
          return null;
        }

      });

      console.log({ filteredList: filteredAthletes });

      let filteredEvents = this.state.objData.events;
      let athleteSignUps = this.state.objData.athleteSignUps;

      filteredEvents.forEach(function (event) {
        var numberAthlete = 0;
        for (let n = 0; n < athleteSignUps.length; n++) {
          if (athleteSignUps[n].EventId == event.EventId && athleteSignUps[n].SignedUp == true) {
            if (filteredAthletes.filter((e) => { return e.AthleteId === athleteSignUps[n].AthleteId ? e : null; }).length > 0)
              numberAthlete++;
          }

        }
        event.numberAthletes = numberAthlete;
      }
      );

      console.log({ options: this.state.displayOptions, filteredEvents: filteredEvents })
      if (this.state.displayOptions.paticipatingEventsOnly === true) {
        filteredEvents = filteredEvents.filter((e) => { return e.numberAthletes > 0 ? e : null; });
      }

      console.log({ dispOpt: this.state.displayOptions, teamDiv: this.state.team, localList: filteredAthletes, localEvent: filteredEvents })
      return (
        <div className="GridView">
          <PageTitle team={this.state.team} meet={this.state.objData.meet} />
          <DisplayOptions displaychange={this.displayChange} currentDisplayOptions={this.state.displayOptions} printerFriendly={this.state.displayOptions.printerFriendly} showingTimes={false} />
          <DisplayFilters displaychange={this.displayChange} currentDisplayOptions={this.state.displayOptions} divisions={this.state.team.Divisions} printerFriendly={this.state.displayOptions.printerFriendly} />
          <table>
            <thead >
              <GridHeader data={filteredEvents} options={this.state.displayOptions} />
            </thead>
            <tbody>
              {

                filteredAthletes.map((e) => {
                  // Is this athlete in the selected age groups

                  let athEvents = this.state.objData.athleteSignUps.filter(item => item.AthleteId === e.AthleteId && item.SignedUp == true);
                  if (this.state.objData.outList !== undefined && this.state.objData.outList.includes(e.AthleteId) === true) {
                    if (showOuts)
                      return (
                        <ItemDataOut athlete={e} key={e.AthleteId} events={filteredEvents} options={this.state.displayOptions} />
                      )
                    else
                      return null;

                  }
                  else {
                    return (
                      <ItemData athlete={e} key={e.AthleteId} signUps={athEvents} events={filteredEvents} options={this.state.displayOptions} />
                    )

                  }
                }
                )

              }
            </tbody>
            <tfoot>
              <GridFooter data={filteredEvents} options={this.state.displayOptions} />
            </tfoot>
          </table>
        </div>
      )
    }
  }

  componentDidMount() {
    const fireModel = new FirestoreModel();

    let search = window.location.search;
    let params = new URLSearchParams(search);

    if (params.get('Team') !== null)
      this.teamId = params.get('Team');

    if (params.get('Meet') !== null)
      this.meetId = params.get('Meet');

    if (this.teamId === '') {
      // YIG
      this.teamId = '3zOTCka27oL4mU9NQDMS';
      // YIB
      //teamId = 'mYB9mYnahkvLezRvkc0E';
      // BG
      //teamId = 'qA5pekBbfAVBOu1TH39C';
      this.meetId = 'o3UJp4LwP0Xvasg9rxeH';
    }

    console.log({ teamId: this.teamId, MeetId: this.meetId });

    let events = [];
    let athleteList = [];
    let outList = [];
    let notSignedUp = [];
    let athleteSignUps = [];
    let team = {};
    let meet = {};

    Promise.all(
      [
        fireModel.fetchMeetTeamTagsAsync(this.teamId, this.meetId),
        fireModel.fetchMeetTeamEventSignupsAsync(this.teamId, this.meetId),
        //fireModel.fetchAllEventsAsync(),
        fireModel.fetchPublicTeamList(this.teamId),
        fireModel.fetchTeamAsync(this.teamId),
        fireModel.fetchMeetAsync(this.meetId)

        //fireModel.fetchAllEventsAsync()
      ]
    ).then((values) => {
      notSignedUp = values[0].NotSignedUp;
      outList = values[0].OutList;
      athleteList = values[2];
      athleteSignUps = values[1].athleteSignUps;

      team = values[3];
      meet = values[4];
      //events = team.AgeEvents;

      // This can be optimized, only read defaul list if there are missing ageEvents
      // however this should be default usage really
      Promise.all([
        fireModel.fetchDefaultTeamEvents(team)
      ]
      ).then((values) => {
        const eventsPossibleDupe = values[0];

        // get the default event list only for  those age groups that dont have specified AgeEvents
        console.log({ eventsPossibleDupe });
        console.log({ obj: team.AgeEvents });
        eventsPossibleDupe.forEach((item) => {
          if (team.AgeEvents.find((e) => e.DivisionId === item.DivisionId) === undefined) {
            if (events.filter((e) => e.EventId === item.EventId).length === 0) {
              events.push(item);
            }

          }
        })

        // Now add events from the teams age groups
        team.AgeEvents.forEach((item) => {
          if (events.filter((e) => e.EventId === item.EventId).length === 0) {
            events.push(item);
          }

        }
        )


        events.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1)

        team.Divisions.map((e) => { e.checked = true; return e; })

        // Create a new attribute in events summing the number of athletes
        events.forEach(function (event) {
          var numberAthlete = 0;
          for (let n = 0; n < athleteSignUps.length; n++) {
            if (athleteSignUps[n].EventId == event.EventId)
              numberAthlete++;
          }
          event.numberAthletes = numberAthlete;

        }
        );


        let queryDate = { notSignedUp, outList, athleteList, athleteSignUps, events, teamId: this.teamId, meetId: this.meetId, meet };


        this.setState({ objData: queryDate, isFetching: false, team: team });

      }
      )




    })
  }

  componentWillUnmount() {
  }
}



export default GridView;
