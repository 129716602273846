import Gender from "./gender.js"

class AgeGroup {
    constructor() {
        this.id = "";
        this.AgeRange = "";
        this.Description = "";
        this.DivisionFilter = "";
        this.Gender = new Gender();
        this.GenderDescription = "";
    }

    AgeOrder() {
        let x = this.AgeRange.split('-', 2)
        if (x.length > 0) {
            const n = parseInt(x[0].trimStart())
            return n;

        }

    }

    SetFromObj(divn) {
        this.id = divn.AgeGroupId !== undefined ? divn.AgeGroupId : divn.id;
        this.AgeRange = divn.AgeRange;
        this.AthleticNetDivisionCode = divn.AthleticNetDivisionCode;
        this.Description = divn.Description;
        this.DivisionFilter = divn.DivisionFilter;
        this.GenderDescription = divn.GenderDescription;
        this.Gender.SetFromCode(divn.Gender.id !== undefined ? divn.Gender.id : divn.Gender);
    }

    getObj() {
        let obj = {}

        obj.AgeGroupId = this.id
        obj.AgeRange = this.AgeRange;
        obj.AthleticNetDivisionCode = this.AthleticNetDivisionCode;
        obj.Description = this.Description;
        obj.DivisionFilter = this.DivisionFilter;
        obj.GenderDescription = this.GenderDescription;
        obj.Gender = this.Gender.genderCode();

        return obj;

    }
};

export default AgeGroup;
