import React, { useState, useEffect, useRef } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button
} from "shards-react";
import FirestoreModel from '../firestoreModel'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

function mapStateToProps(state) {
    return ({
        userAuth: state.userAuth,
        selectedTeam: state.selectedTeam
    });
}

function mapDispatchToProps(dispatch) {

    return {
        onSetTeam: (Team) => {
            console.log({ team: Team });
            dispatch({ type: 'TEAM_SET', Team });
        },
        onSetMeet: (Meet) => {
            dispatch({ type: 'MEET_SET', Meet });
        },
    };

}



const Meets = connect(mapStateToProps, mapDispatchToProps)(function (props) {
    let [userMeets, setUserMeets] = useState({});
    let [meetPicked, setMeetPicked] = useState({});
    let [clubs, setClubs] = useState([]);

    let handleMeetChange = (meet) => {
        setMeetPicked(meet);
        props.onSetMeet(meet);
    }

    useEffect(() => {

        if (props.userAuth === undefined || props.userAuth === null || props.selectedTeam === undefined) {
            return;
        }

        const fireModel = new FirestoreModel();

        Promise.all(
            [
                //fireModel.fetchUserMeetsAsync(props.userAuth.uid)
                fireModel.fetchClubMeetsAsync(props.selectedTeam.ClubId),
                fireModel.fetchClubsAsync()

            ]
        ).then((values) => {
            setUserMeets({ Meets: values[0] });
            setClubs(values[1])
        })

    }, [props.userAuth, props.selectedTeam]);

    let deleteMeet = async () => {
        // delete will only remove the club from the meet so the data is preserved in the back-end
        if (window.confirm("Are you sure?  Click OK to delete, this operation has no undo.")) {
            const obj = meetPicked;

            const fireModel = new FirestoreModel();

            const foundClubInMeet = obj.Clubs.find((e) => { return (e.ClubId === obj.ClubId) });

            const index = obj.Clubs.indexOf(foundClubInMeet);
            if (index > -1) { // only splice array when item is found
                obj.Clubs.splice(index, 1); // 2nd parameter means remove one item only
            }

            await fireModel.putMeet(obj);

            // delete the meet from the local copy
            console.log(userMeets.Meets);
            const newList = userMeets.Meets.filter(e => e.MeetId !== meetPicked.MeetId);
            setUserMeets({ Meets: newList });
            setMeetPicked({});
        }

    }


    if (props.userAuth === null)
        return (
            <div>
                <p>
                    Please sign-in
                </p>
            </div>
        )
    else if (props.selectedTeam === undefined)
        return (
            <div>
                <p>

                </p>
            </div>
        )

    else if (userMeets.Meets === null || userMeets.Meets === undefined || clubs.length === 0)
        return (
            <div>
                <p>
                    Loading....
                </p>
            </div>
        )
    else {
        // Check if the user is a club owner, if so enable edit features
        // this is also checked in backend for security

        const isAdmin = clubs.find((e) => { return e.Id === props.selectedTeam.ClubId }).Roles[props.userAuth.uid] !== undefined;
        const selectedClub = clubs.find((e) => { return e.Id === props.selectedTeam.ClubId });

        if (userMeets.Meets.length === 0 && !isAdmin)
            return (
                <div>
                    <p>
                        No meets available
                    </p>
                </div>
            );

        return (
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">Meets</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                        <thead className="bg-light">
                            <tr>
                                <th scope="col" className="border-0">Date</th>
                                <th scope="col" className="border-0">Meet Name</th>
                                <th scope="col" className="border-0">Reports and actions</th>
                                {isAdmin ? <th scope="col" className="border-0">Admin</th> : ""}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userMeets.Meets.map((meet) => {
                                    return (
                                        <tr key={meet.MeetId} className={meet === meetPicked ? "bg-light" : ""} onClick={(event) => handleMeetChange(meet)}>
                                            <td>{meet.Date}</td>
                                            <td>{meet.Name}</td>
                                            <td>
                                                {props.selectedTeam != null ? <Link to={{ pathname: "gridview", state: { meetId: meet.MeetId, teamId: props.selectedTeam.Id } }}><Button className="mb-2 mr-1" type="button" theme="white">Grid</Button></Link> : ""}
                                                {props.selectedTeam != null ? <Link to={{ pathname: "tableview", state: { meetId: meet.MeetId, teamId: props.selectedTeam.Id } }}><Button className="mb-2 mr-1" type="button" theme="white">Grid-Beta</Button></Link> : ""}
                                                {props.selectedTeam != null ? <Link to={{ pathname: "eventview", state: { meetId: meet.MeetId, teamId: props.selectedTeam.Id } }}><Button className="mb-2 mr-1" type="button" theme="white">List</Button></Link> : ""}
                                                {props.selectedTeam != null ? <Link to={{ pathname: "signupview", state: { meetId: meet.MeetId, teamId: props.selectedTeam.Id } }}><Button className="mb-2 mr-1" type="button" theme="white">Sign ups</Button></Link> : ""}
                                            </td>
                                            <td>
                                                {isAdmin ? props.selectedTeam != null ? <Link to={{ pathname: "meetdetails", state: { meet: meet.MeetId, teamId: props.selectedTeam.Id, club: selectedClub } }}><Button className="mb-2 mr-1" type="button" theme="white">.!.</Button></Link> : "" : ""}

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                        <tfoot>
                            {isAdmin ?
                                <tr>
                                    <td colSpan={2}>
                                        <Link to={{ pathname: "meetdetails", state: { action: "add", club: selectedClub } }}>
                                            <Button className="btn btn-accent" type="button" >Add a new meet to your Club</Button>
                                        </Link>
                                    </td>
                                    <td colSpan={2}>
                                        {meetPicked.MeetId !== undefined ?
                                            <Button className="btn btn-accent" type="button" onClick={deleteMeet}>Delete the entire meet</Button> : ""}
                                    </td>
                                </tr> : ""
                            }
                        </tfoot>
                    </table>
                </CardBody>
            </Card>
        );
    }

}
)
export default Meets;
