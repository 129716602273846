
class AthleteEventSignUp {
    constructor() {
        this.AthleteId = "";
        this.Name = "";
        this.EventId = "";
        this.Record = 0;
        this.RecordType = "";
        this.Notes = "";

        this.Result = 0;
        this.AlsoKnownAs = "";
        this.SignedUp = false;
    }

    getAttribute(object, name1, name2, defaultValue) {
        let value = defaultValue;
        if (object[name1] !== undefined && object[name1] !== null) {
            value = object[name1]
        }
        else {
            if (object[name2] !== undefined && object[name2] !== null) {
                value = object[name2]
            }
        }
        return value;
    }

    fromObject(result) {
        // due to some issue allow object to map with either case
        this.AthleteId = this.getAttribute(result, "AthleteId", "athleteId", "");
        this.Name = this.getAttribute(result, "Name", "name", "")
        this.EventId = this.getAttribute(result, "EventId", "eventId", "")
        this.Record = this.getAttribute(result, "Record", "record", 0)
        this.RecordType = this.getAttribute(result, "RecordType", "recordType", "")
        this.Notes = this.getAttribute(result, "Notes", "notes", "")
        this.AlsoKnownAs = this.getAttribute(result, "AlsoKnownAs", "alsoKnownAs", "")
        this.Result = this.getAttribute(result, "Result", "result", 0)
        this.SignedUp = this.getAttribute(result, "SignedUp", "signedUp", false)
    }

    toObject() {
        return {
            AthleteId: this.AthleteId,
            Name: this.Name,
            EventId: this.EventId,
            Record: this.Record,
            RecordType: this.RecordType,
            Notes: this.Notes,
            Result: this.Result,
            AlsoKnownAs: this.AlsoKnownAs,
            SignedUp: this.SignedUp
        }
    }

    resultText(event) {
        if (this.Record == 0)
            return "";
        if (event.EventTypeId === 1) {
            var feet = Math.floor(this.Record / 1200.0);
            var inches = (this.Record - (feet * 1200)) / 100.0;
            //return `${feet}${inches % 2.3f
            return `${feet}'${inches}`
        }
        else {
            var minutes = Math.floor(this.Record / 6000);
            var seconds = (this.Record - (minutes * 6000)) / 100.0;
            //return `${ minutes }% u: ${ seconds } .2f`;
            if (minutes > 0) {
                if (seconds < 10)
                    return `${minutes}.0${seconds.toFixed(2)}`;
                return `${minutes}.${seconds.toFixed(2)}`;
            }

            return `${seconds.toFixed(2)}`;
        }
    }

    compare(timedFlag, otherOne) {
        if (timedFlag === true) {
            let a = this.Record == 0 ? 999999 : this.Record;
            let b = otherOne.Record == 0 ? 999999 : otherOne.Record;
            return a - b;
        }

        return otherOne.Record - this.Record;
    }
};


export default AthleteEventSignUp;
