import React from "react";
import { Container, Row, Col, Button } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import MyTeams from "../components/MyTeams";
import Athletes from "../components/Athletes";
import UserAccount from "../components/user-profile-lite/UserAccount";
import Meets from "../components/meets";

import { connect } from 'react-redux'

function mapStateToProps(state) {
  // Called whenever the stored state changes
  return ({
    userAuth: state.userAuth,
    selectedTeam: state.selectedTeam
  });
}

function mapDispatchToProps(dispatch) {
  // Creates a props methiod that can be called whenever needed, you want to trigger a state change
  // This code executes when called.
  return {
    onSetTeam: (Team) => {
      console.log({ team: Team });
      dispatch({ type: 'TEAM_SET', Team });
    },
    onSignIn: (userAuth) => {
      dispatch({ type: 'SIGNED_IN', userAuth });
    },
    onSignOut: (userAuth) => {
      dispatch({ type: 'SIGNED_OUT', userAuth });
    }

  };
}

const TeamDashboard = connect(mapStateToProps, mapDispatchToProps)(function (props) {

  let signedIn = !(props.userAuth === undefined || props.userAuth === null)

  if (signedIn) {
    if (props.userAuth.uid === null)
      signedIn = false;
  }

  console.log(props.userAuth)

  let primaryTeamPrompt = ''

  if (signedIn) {
    if (props.selectedTeam === undefined || props.selectedTeam === null) {
      if (props.userAuth.UserDetails.ProfileTeams === undefined)
        primaryTeamPrompt = "";
      else
        primaryTeamPrompt = "Please select a primary team"
    }
  }


  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <Col className="text-left" lg="3">
          <strong className="text-muted d-block my-2">
            {primaryTeamPrompt}
          </strong>
        </Col>

      </Row>
      <Row>
        {
          signedIn ? '' : <Col lg="6"><UserAccount /></Col>
        }

        {
          signedIn ?
            <Col lg="8">
              <MyTeams />
              <Meets />
            </Col>
            : ""
        }


      </Row>
    </Container>

  )
}
);

export default TeamDashboard;
