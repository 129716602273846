import React, { useState, useEffect } from "react";
import { Nav } from "shards-react";

import { connect } from 'react-redux'

import Notifications from "./Notifications";
import UserActions from "./UserActions";

import FirestoreModel from '../../../../firestoreModel'

function mapStateToProps(state) {
  console.log({ state: state });
  return ({
    userAuth: state.userAuth,
    selectedTeam: state.selectedTeam
  });
}

function mapDispatchToProps(dispatch) {

  return {
    onSignIn: (userAuth) => {
      dispatch({ type: 'SIGNED_IN', userAuth });
    },
    onSignOut: (userAuth) => {
      dispatch({ type: 'SIGNED_OUT', userAuth });
    }
  };
}

const NavBarNav = connect(mapStateToProps, mapDispatchToProps)(function (props) {

  let [invites, setInvites] = useState([]);

  useEffect(() => {
    if (props.userAuth === null)
      return;
    const fireModel = new FirestoreModel();

    Promise.all(
      [
        fireModel.getUserInvites(props.userAuth.uid, props.userAuth.email)

      ]
    ).then((values) => {
      setInvites(values[0])
      console.log({ invites: invites })
    })


  }, [props.userAuth]);

  return (
    <Nav navbar className="border-left flex-row">
      <Notifications teamInvites={invites} />
      <UserActions />
    </Nav>
  )
}
)

export default NavBarNav;
