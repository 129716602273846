import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button, CardHeader, CardBody, Badge } from "shards-react";
import { Link, useLocation, Redirect } from 'react-router-dom';
import PageTitle from "../components/common/PageTitle";
import { connect } from 'react-redux'
import FirestoreModel from '../firestoreModel'
import Roster from "../utils/Roster";
import Athlete from "../utils/athlete";
import AthleteSignUpComponent from "../components/AthleteSignUpComponent";
import AthleteEventSignUp from "../utils/athletesignup"

function mapStateToProps(state) {
    return ({
        userAuth: state.userAuth,
        selectedTeam: state.selectedTeam,
        selectedMeet: state.selectedMeet
    });
}

function mapDispatchToProps(dispatch) {

    return {
        onSetTeam: (Team) => {
            console.log({ team: Team });
            dispatch({ type: 'TEAM_SET', Team });
        },
        onSignIn: (userAuth) => {
            dispatch({ type: 'SIGNED_IN', userAuth });
        },
        onSignOut: (userAuth) => {
            dispatch({ type: 'SIGNED_OUT', userAuth });
        }

    };
}

const SignUpView = connect(mapStateToProps, mapDispatchToProps)(function (props) {
    let [athleteList, setAthleteList] = useState({});
    let [outList, setOutList] = useState({});
    let [notSignedUp, setNotSignedUp] = useState({});
    let [athleteSignUps, setAthleteSignUps] = useState({});
    let [athleteHighlighted, setAthleteHighlighted] = useState({ athlete: null, changesDetected: null });
    let [teamEvents, setTeamEvents] = useState({});

    const fireModel = new FirestoreModel();


    //const teamRef = useRef(athleteList);

    useEffect(() => {
        if (props.selectedTeam === undefined || props.selectedTeam === null) {
            return;
        }

        if (props.selectedMeet === undefined || props.selectedMeet === null) {
            return;
        }

        const meetId = props.selectedMeet.MeetId;
        const teamId = props.selectedTeam.Id;

        Promise.all(
            [
                fireModel.fetchMeetTeamTagsAsync(teamId, meetId),
                fireModel.fetchMeetTeamEventSignupsAsync(teamId, meetId),
                fireModel.fetchPublicTeamList(teamId),
                //fireModel.fetchAllEventsAsync(),
                //fireModel.fetchDefaultTeamEvents(props.selectedTeam),
                fireModel.fetchTeamAsync(teamId),
            ]
        ).then((values) => {
            // New meet doesnt have an notsign / out list
            if (values[0] === undefined) {
                setNotSignedUp([]);
                setOutList([]);
            }
            else {
                setNotSignedUp(values[0].NotSignedUp);

                if (values[0].OutList != null)
                    setOutList(values[0].OutList);
                else
                    setOutList([]);
            }
            setAthleteList(values[2]);
            setAthleteSignUps(values[1].athleteSignUps);


            // Get age events from the team or if not there from the division defaults
            // it is possible that not all age groups have overrider, therefore we must merge the missing 
            // age groups into those that are set.
            const team = values[3];

            // Check to see if all age groups have data, if so dont bother getting defaults
            const ageGroupList = [];

            team.Divisions.forEach((div) => {
                let objCheck = ageGroupList.find((e) => e === div.AgeGroupId);
                if (ageGroupList.find((e) => e === div.AgeGroupId) === undefined) {
                    ageGroupList.push(div.AgeGroupId);
                }
            }
            )

            let missingFound = false;

            if (team.AgeEvents === undefined)
                team.AgeEvents = [];

            team.Divisions.forEach((div) => {
                if (team.AgeEvents.find((e) => e.DivisionId === div.AgeGroupId) === undefined)
                    missingFound = true;
            }
            )

            console.log(missingFound)
            // If there was a missing set of age events fill it in from detault
            //if (team.AgeEvents == null || team.AgeEvents.length === 0) {
            if (missingFound) {
                Promise.all(
                    [
                        fireModel.fetchDefaultTeamEvents(team)
                    ]
                ).then((values) => {
                    team.Divisions.forEach((div) => {
                        if (team.AgeEvents.find((e) => e.DivisionId === div.AgeGroupId) === undefined) {
                            // push the data from default to the age event list - hope it is same format
                            console.log({ notFound: div })
                            values[0].forEach((defaultEvent) => {
                                if (defaultEvent.DivisionId === div.AgeGroupId) {
                                    team.AgeEvents.push(defaultEvent);
                                }
                            })

                        }
                    }
                    )

                    //setTeamEvents(values[0]);
                    setTeamEvents(team.AgeEvents);

                    console.log({ teamEventsWithAddedDefault: team.AgeEvents });
                })
            }
            else
                setTeamEvents(values[3].AgeEvents);

        })

    }, [props.selectedTeam])

    let handleChange = (event) => {
    }

    const textAreaStyle = {
        whiteSpace: 'pre',
        overflowWrap: 'normal',
        overflowX: 'scroll',
        width: '100%'
    };

    const teamLoaded = athleteList.length !== undefined;

    let handleAthleteChange = (athlete) => {
        console.log({ athleteHighlightedChanged: athlete });
        // closing up the selected athlete
        if (athleteHighlighted.changesDetected !== null) {
            // there are changes to consider, make the changes before continuing
            let changesToConsider = athleteHighlighted.changesDetected;
            let newAthleteSignUps = athleteSignUps.filter((p) => p.AthleteId !== athleteHighlighted.athlete.AthleteId);

            changesToConsider.forEach((p) => {
                if (p.athleteSignUp.length === 1 && p.athleteSignUp[0].in === true) {
                    let athleteEventSignUp = new AthleteEventSignUp();

                    athleteEventSignUp.AthleteId = athleteHighlighted.athlete.AthleteId;
                    athleteEventSignUp.Name = athleteHighlighted.athlete.Name;
                    athleteEventSignUp.EventId = p.EventId;
                    athleteEventSignUp.Record = "";
                    athleteEventSignUp.RecordType = "";
                    athleteEventSignUp.Notes = p.athleteSignUp[0].Notes;
                    athleteEventSignUp.SignedUp = true;

                    newAthleteSignUps.push(athleteEventSignUp);
                }
            }
            )
            setAthleteSignUps(newAthleteSignUps);

        }

        if (athleteHighlighted.athlete === athlete)
            setAthleteHighlighted(Object.assign({}, athleteHighlighted, { athlete: null, changesDetected: null }));
        else
            setAthleteHighlighted(Object.assign({}, athleteHighlighted, { athlete: athlete, changesDetected: null }));

        var stickyContainer = document.getElementById(athlete.AthleteId);
        var posn = stickyContainer && stickyContainer.offsetTop ? stickyContainer.offsetTop : 0;
        console.log(posn);

    }

    let toggleOut = (e, athleteId, participating) => {
        e.stopPropagation();
        // find local entry
        const newOutList = outList.filter((e) => e !== athleteId);

        if (participating === true) {
            const id = newOutList.indexOf(athleteId);
            if (id >= 0)
                newOutList.splice(id, 1);
        }
        else {
            newOutList.push(athleteId);
        }

        const fireModel = new FirestoreModel();

        Promise.all(
            [

                fireModel.putOutList(props.selectedTeam.Id, props.selectedMeet.MeetId, newOutList)
            ]
        ).then((values) => {
            console.log("done");
        })

        setOutList(newOutList);
    }

    const isSmall = window.innerWidth < 900;
    let numCols = 8
    if (isSmall)
        numCols = 4;

    console.log({ size: isSmall, width: window.innerWidth });

    let handleSignupChanges = (values) => {
        // this is called by the subcomponent when a selection was changed.
        // now we need to make sure the data at this parent level matches the DB
        // so that the data is shown correctly when the user navigates away.
        // we should only update the state when the user navigates away from the
        // selected athlete.
        setAthleteHighlighted(Object.assign({}, athleteHighlighted, { changesDetected: values }));
    }


    return (

        <Container fluid className="main-content-container px-4">
            <Row noGutters className="page-header py-4">
                <PageTitle title={props.selectedMeet != null ? props.selectedMeet.Name : "Select a meet"} subtitle="Athlete sign-ups for meet" md="12" className="ml-sm-auto mr-sm-auto" />
            </Row>
            <Row>
                <Col lg="12">
                    <Card small className="mb-4 pt-3">
                        <CardBody>
                            {
                                teamLoaded === false ? <div>loading</div> :
                                    <div className="mb-3 mx-auto">
                                        <table className="table mb-0" >
                                            <thead className="bg-light">
                                                {isSmall === false ?
                                                    <tr>
                                                        <th scope="col" className="border-0">Name</th>
                                                        <th scope="col" className="border-0">AKA</th>
                                                        <th scope="col" className="border-0" colSpan="2">No Show</th>
                                                        <th scope="col" className="border-0" colSpan="1">Track</th>
                                                        <th scope="col" className="border-0" colSpan="1">Field</th>
                                                        <th scope="col" className="border-0" colSpan="1">Free</th>
                                                        <th scope="col" className="border-0" colSpan="1">Total</th>
                                                    </tr> :
                                                    <tr>
                                                        <th scope="col" className="border-0">Name</th>
                                                        <th scope="col" className="border-0" colSpan="2">No Show</th>
                                                        <th scope="col" className="border-0" colSpan="1">Total</th>
                                                    </tr>}
                                            </thead>
                                            <tbody>
                                                {
                                                    teamEvents.length === undefined || athleteSignUps.length === undefined ? null :

                                                        athleteList.map((athleteObj) => {
                                                            let countMeasured = 0;
                                                            let countFree = 0;
                                                            let countTimed = 0;
                                                            let countTotal = 0;

                                                            if (athleteSignUps != null && athleteSignUps.length != undefined && teamEvents.length != undefined) {
                                                                const thisAthletesTotals = athleteSignUps.filter((e) => e.AthleteId === athleteObj.AthleteId && e.SignedUp);

                                                                thisAthletesTotals.forEach(element => {
                                                                    let eventList = teamEvents.filter((e) => e.EventId == element.EventId);
                                                                    console.log({ eventList, element, length: eventList.length });

                                                                    try {
                                                                        if (eventList != null && eventList.length != undefined && eventList.length > 0) {
                                                                            if (eventList[0].EventId == 14)
                                                                                countFree++;
                                                                            else {
                                                                                if (eventList[0].EventTypeId == 0)
                                                                                    countTimed++;
                                                                                else
                                                                                    countMeasured++;
                                                                            }
                                                                        }
                                                                        countTotal = thisAthletesTotals.length - countFree;
                                                                    }
                                                                    catch
                                                                    {
                                                                        console.log("Exception");
                                                                    }
                                                                });

                                                            }

                                                            return (
                                                                <React.Fragment key={athleteObj.Name + athleteObj.AthleteId}>
                                                                    {isSmall === false ?
                                                                        <tr id={athleteObj.AthleteId} className={athleteHighlighted.athlete !== null && athleteObj.AthleteId === athleteHighlighted.athlete.AthleteId ? "bg-light" : ""} onClick={(event) => handleAthleteChange(athleteObj)}>
                                                                            <td>{athleteObj.Name}</td>
                                                                            <td>{athleteObj.AlsoKnownAs}</td>
                                                                            <td>
                                                                                {outList !== null && outList.includes(athleteObj.AthleteId) ?
                                                                                    <img
                                                                                        id="main-logo"
                                                                                        className="d-inline-block align-top mr-1"
                                                                                        style={{ maxWidth: "25px" }}
                                                                                        src={require("../images/crossenabled.png")}
                                                                                        alt="Athlete out"
                                                                                        onClick={(event) => { toggleOut(event, athleteObj.AthleteId, true) }}
                                                                                    />
                                                                                    :
                                                                                    <img
                                                                                        id="main-logo"
                                                                                        className="d-inline-block align-top mr-1"
                                                                                        style={{ maxWidth: "25px" }}
                                                                                        src={require("../images/crossdisabled.png")}
                                                                                        alt="Athlete in"
                                                                                        onClick={(event) => { toggleOut(event, athleteObj.AthleteId, false) }}
                                                                                    />}

                                                                            </td>
                                                                            <td>{outList.includes(athleteObj.AthleteId) ? "OUT" : ""}</td>
                                                                            <td>{countTimed}</td>
                                                                            <td>{countMeasured}</td>
                                                                            <td>{countFree}</td>
                                                                            <td>{countTotal}</td>
                                                                        </tr> :
                                                                        <tr id={athleteObj.AthleteId} className={athleteHighlighted.athlete !== null && athleteObj.AthleteId === athleteHighlighted.athlete.AthleteId ? "bg-light" : ""} onClick={(event) => handleAthleteChange(athleteObj)}>
                                                                            <td>{athleteObj.Name}</td>
                                                                            <td>
                                                                                {outList.includes(athleteObj.AthleteId) ? <img
                                                                                    id="main-logo"
                                                                                    className="d-inline-block align-top mr-1"
                                                                                    style={{ maxWidth: "25px" }}
                                                                                    src={require("../images/crossenabled.png")}
                                                                                    alt="Athlete out"
                                                                                    onClick={(event) => { toggleOut(event, athleteObj.AthleteId, true) }}
                                                                                />
                                                                                    : <img
                                                                                        id="main-logo"
                                                                                        className="d-inline-block align-top mr-1"
                                                                                        style={{ maxWidth: "25px" }}
                                                                                        src={require("../images/crossdisabled.png")}
                                                                                        alt="Athlete in"
                                                                                        onClick={(event) => { toggleOut(event, athleteObj.AthleteId, false) }}
                                                                                    />}

                                                                            </td>
                                                                            <td>{outList.includes(athleteObj.AthleteId) ? "OUT" : ""}</td>
                                                                            <td>{countTotal}</td>
                                                                        </tr>
                                                                    }
                                                                    {athleteHighlighted.athlete !== null && athleteObj.AthleteId === athleteHighlighted.athlete.AthleteId ?
                                                                        <tr>
                                                                            <td colSpan={numCols}>
                                                                                <AthleteSignUpComponent onChangesDetected={handleSignupChanges} athlete={athleteHighlighted.athlete} meet={props.selectedMeet} signups={athleteSignUps} teamEvents={teamEvents} team={props.selectedTeam} />
                                                                            </td>
                                                                        </tr>
                                                                        : null}
                                                                </React.Fragment>

                                                            )
                                                        }


                                                        )
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                            }
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </Container >

    )
});

export default SignUpView;
