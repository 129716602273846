import AthleteEventSignUp from "./athletesignup"

class RecordHelper {
    constructor(data) {
        this.RecordData = data
        this.RecordType = ""
        this.Result = 0

        if (data != null) {
            this.RecordType = data.RecordTypeDesc
            this.Result = data.BaseResult
        }
    }

    getResultString() {
        const aes = new AthleteEventSignUp();

        aes.EventId = this.RecordData.Event.EventId;
        aes.Record = this.RecordData.BaseResult;
        aes.RecordType = this.RecordData.RecordTypeDesc;

        return aes.resultText(this.RecordData.Event)
    }


}

export default RecordHelper;