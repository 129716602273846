class Roster {
    constructor() {
        this.originalTeam = [];
        this.proposedChanged = [];
        this.proposedAdded = [];
        this.proposedRemoved = [];
        this.proposedUnchanged = [];
        this.action = 0;
    }

    Actions = Object.freeze({ Replace: 1, Merge: 0 });

    validateRosterChanges(newRoster, action) {
        // To-do rare circumstance where 2 athletes have same name
        // the original team can be empty or can be populated
        console.log(this.originalTeam, newRoster)

        for (let i = 0; i < newRoster.length; i++) {
            const proposedAthlete = newRoster[i];
            if (action === this.Actions.Replace)
                // Replacing, therefore this is an add
                this.proposedAdded.push(proposedAthlete);
            else {
                const matchingAthleteList = this.originalTeam.filter(item => item.Name === proposedAthlete.Name);

                if (matchingAthleteList.length === 0) {
                    this.proposedAdded.push(proposedAthlete);
                }
                else {
                    // A match was found, if more than one match this will not work
                    if (matchingAthleteList.length > 1)
                        return "Duplicate Names found merge cannot be processed.";
                    proposedAthlete.id = proposedAthlete.AthleteId = matchingAthleteList[0].AthleteId;
                    this.proposedChanged.push(proposedAthlete);

                }
            }
        }

        // If we are replacing, all the existing need to be added to the remove collection
        if (action === this.Actions.Replace)
        // Replacing, therefore this is an add
        {
            this.originalTeam.forEach((originalAthlete) => { this.proposedRemoved.push(originalAthlete) });
        }

        // For display only create the unchanged list
        this.originalTeam.forEach((originalAthlete) => {
            if (this.proposedRemoved.filter(item => item.Name === originalAthlete.Name).length === 0) {
                if (this.proposedChanged.filter(item => item.Name === originalAthlete.Name).length === 0) {
                    this.proposedUnchanged.push(originalAthlete)
                }
            }
        });


        return "";
    }
};

export default Roster;
