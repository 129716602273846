import React from "react";
import { Container, Row, Col } from "shards-react";
import {
    Card,
    CardHeader
} from "shards-react";


import PageTitle from "../components/common/PageTitle";

const PrivacyPolicy = () => (
    <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
            <PageTitle title="Privacy Policy" subtitle="Our policy" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
            <Col lg="8">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Our Simple Policy</h4>
                            <p className="text-muted d-block mb-2">This application will not collect your personal information. This application will not read any information from your device other than your email address. Email address is required to validate that you are a track coach or team manager. Your email address will never be shared with any other company or person. Period. We are coaches and parents and realize this committment is important. All performance and timing data is public information freely available at other web sites including your conferecce site. Thank you for your support. If you need any more information please feel free to email me using this <a href="mailto:drhodgson@gmail.com">email address.</a></p>
                        </div>
                    </CardHeader>
                </Card>
            </Col>
            <Col lg="4">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Timing Information</h4>
                            <span className="text-muted d-block mb-2">Timing information referenced within this web site and mobile app is public information and is freely available on web sites such as your conference's.</span>
                        </div>
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Athlete Information</h4>
                            <span className="text-muted d-block mb-2">We do not collect any personal information about you or your athletes.  Race day reports ARE available to anybody with the linik.  Howver, only the names will appear on the reports.  No other information will be visible.</span>
                        </div>
                    </CardHeader>
                </Card>
            </Col>
        </Row>
    </Container>
);

export default PrivacyPolicy;
